import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';

import Button from 'shared/ui/button/Button';

import styles from './LeavePageModal.module.scss';

type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onLeaveBtnClick: () => void;
  onStayBtnClick: () => void;
};

function LeavePageModal(props: Props) {
  const { isOpen, setIsOpen, onStayBtnClick, onLeaveBtnClick } = props;
  return (
    <Dialog
      className={styles.leavePageModal}
      maxWidth="sm"
      open={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <div className={styles.leavePageModalBody}>
        <div className={styles.leavePageModalContent}>
          <Typography variant="h6">
            Are you sure you want to close this window?
          </Typography>
          <Typography variant="body1">Your trial will not be saved.</Typography>
          <div className={styles.leavePageModalButtons}>
            <Button
              color="primary"
              testId="stay_on_page"
              variant="outlined"
              onClick={onStayBtnClick}
            >
              Keep editing
            </Button>
            <Button
              color="primary"
              testId="leave_page"
              variant="contained"
              onClick={onLeaveBtnClick}
            >
              Close
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default LeavePageModal;
