import { type ReactElement } from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { alpha } from '@mui/system/colorManipulator';
import { Link } from 'react-router-dom';

import logoFullWhite from 'shared/assets/images/logo-full-white.svg';
import logoFull from 'shared/assets/images/logo-full.png';
import DevBar from 'shared/components/dev-bar/DevBar';
import SkipLinks from 'shared/components/skip-links/SkipLinks';
import IconButton from 'shared/ui/icon-button/IconButton';

import config from 'config';
import { getCompanyDashboard } from 'routes';
import HelpDropdown from 'shared/lib/help-dropdown/HelpDropdown';
import UserDropdown from 'shared/lib/user-dropdown/UserDropdown';

import ThemeMode from './ThemeMode';

function PartnerAppToolbar(): ReactElement {
  const paletteMode = useTheme().palette.mode;
  const bannerLogo = paletteMode === 'light' ? logoFull : logoFullWhite;

  return (
    <AppBar
      position="relative"
      sx={{
        background: (theme) => theme.palette.background.default,
        color: (theme) => theme.palette.primary.main,
        flex: 0,
        // make sure this is the "most top" AppBar
        zIndex: (theme) => theme.zIndex.appBar + 1,
      }}
    >
      <SkipLinks />
      {config.VITE_APP_IS_DEV_ENVIRONMENT && <DevBar />}
      <Toolbar sx={{ height: 9 }} disableGutters>
        <Link to={getCompanyDashboard()}>
          <IconButton sx={{ width: 48, height: 48 }}>
            <ArrowBackIcon
              fontSize="small"
              sx={{
                color: ({ palette }) => alpha(palette.common.black, 0.56),
              }}
            />
          </IconButton>
        </Link>
        <Box component="img" height={32} ml={1.5} mr={1.5} src={bannerLogo} />
        <Typography
          color="text.secondary"
          component="div"
          sx={{ ml: 2, flexGrow: 1 }}
          variant="h4"
        >
          Partner Dashboard
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <ThemeMode />
        <HelpDropdown />
        <UserDropdown />
      </Toolbar>
    </AppBar>
  );
}
export default PartnerAppToolbar;
