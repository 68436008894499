import { useMemo } from 'react';

import type {
  CellEditingStartedEvent,
  NewValueParams,
  ValueFormatterParams,
} from '@ag-grid-community/core';
import { useTheme } from '@mui/material/styles';

import {
  getDateCellConfig,
  getGenericCellConfig,
  getNullOrDecimalConfig,
  getPercentCellConfig,
  getPercentCompleteCellConfig,
  getRowNumberConfig,
  getSelectCellConfig,
  getToggleableMoneyCellConfig,
  makeEditableIf,
} from 'shared/components/ag-grid-cells/config';
import type {
  CondorColDef,
  CondorColGroupDef,
} from 'shared/components/ag-grid/types';

import {
  ActivityUnitDetail,
  ActivityUnitType,
  detailChoicesForUnitType,
} from 'shared/lib/driverUnitTypes';
import useHasPermission from 'shared/lib/permissions/useHasPermission';
import type {
  CroCostCategory,
  RegionAndRegionGroupResponse,
} from 'shared/lib/types';

import type { apiJSON } from 'shared/api/rtkq/apiJSON';

import { getCroUnitTypeChoices } from '../helpers';

export function processCroExpenseGridColumnDefs(
  isOpenPeriod: boolean,
  expenseData: apiJSON | undefined,
  costCategory: CroCostCategory,
  themeMode: 'dark' | 'light' | undefined,
  canEditTrialInfo: boolean,
  regionsAndRegionGroups?: RegionAndRegionGroupResponse[],
  isGridLocked?: boolean,
) {
  const ASSIGNED_REGION_WIDTH = 150;
  const canEditData = isOpenPeriod && canEditTrialInfo && !isGridLocked;

  const columnDefs = [
    {
      headerName: '',
      children: [
        {
          field: 'displayRowNum',
          headerName: 'Row',
          pinned: 'left',
          width: 80,
          ...getRowNumberConfig(),
        },
        {
          field: 'activityDescription',
          headerName: 'Description',
          pinned: 'left',
        },
      ],
    },
    {
      headerName: 'Contract',
      children: [
        { field: 'id', hide: true },
        { field: 'contractVersionTraceId', hide: true },
        { field: 'vendorName', hide: true },
        { field: 'traceId', hide: true },
        { field: 'hasEnrollment', hide: true },
        { field: 'hasTreatment', hide: true },
        { field: 'hasFollowup', hide: true },
        { field: 'serviceCategory', rowGroup: true, hide: true },
        { field: 'region' },
        { field: 'activityCode', headerName: 'Activity Code' },
        { field: 'pizzaUnitType', headerName: 'Contracted Unit Type' },
        {
          field: 'pizzaUnitCount',
          headerName: '# of Units',
          ...getNullOrDecimalConfig(),
        },
        {
          headerName: 'Unit Price',
          ...getToggleableMoneyCellConfig('pizza_unit_price', {
            cellRendererParams: { useEmDashInTotal: false },
          }),
        },
        {
          headerName: 'Total Contract Value',
          ...getToggleableMoneyCellConfig('current_contract_value'),
          aggFunc: 'sum',
        },
      ],
    },
    {
      headerName: 'Activity Driver Assignments',
      children: [
        { field: 'activityDriverTraceId', hide: true },
        {
          field: 'unitType',
          filter: true,
          ...(canEditData && {
            ...getSelectCellConfig({ useEmDashInTotal: false }),
            cellEditorParams: { values: getCroUnitTypeChoices(costCategory) },
            onCellValueChanged(event: NewValueParams) {
              event.node?.setDataValue('unitDetail', null);
            },
            ...makeEditableIf(
              ({ data }) => data && data.type !== 'BOTTOM_LINE_ADJUSTMENT',
              themeMode,
            ),
          }),
        },
        {
          field: 'unitDetail',
          filter: true,
          ...(canEditData && {
            ...getSelectCellConfig({ useEmDashInTotal: false }),
            cellEditorParams(params: CellEditingStartedEvent) {
              return {
                values: detailChoicesForUnitType(
                  params.data.unitType,
                  'CRO',
                ).filter(
                  (unitDetail) =>
                    (unitDetail !== ActivityUnitDetail.ENROLLMENT_PERIOD ||
                      params.data.hasEnrollment === true) &&
                    (unitDetail !== ActivityUnitDetail.TREATMENT_PERIOD ||
                      params.data.hasTreatment === true) &&
                    (unitDetail !== ActivityUnitDetail.FOLLOW_UP_PERIOD ||
                      params.data.hasFollowup === true),
                ),
              };
            },
            ...makeEditableIf(
              ({ data }) =>
                data?.unitType && data.type !== 'BOTTOM_LINE_ADJUSTMENT',
              themeMode,
            ),
          }),
        },
        {
          field: 'startDate',
          ...(canEditData
            ? {
                ...getDateCellConfig({ useEmDashInTotal: false }),
                ...makeEditableIf(
                  ({ data }) => data?.unitDetail === ActivityUnitDetail.CUSTOM,
                  themeMode,
                ),
              }
            : { ...getGenericCellConfig({ useEmDashInTotal: false }) }),
        },
        {
          field: 'endDate',
          ...(canEditData
            ? {
                ...getDateCellConfig({ useEmDashInTotal: false }),
                ...makeEditableIf(
                  ({ data }) => data?.unitDetail === ActivityUnitDetail.CUSTOM,
                  themeMode,
                ),
              }
            : { ...getGenericCellConfig({ useEmDashInTotal: false }) }),
        },
        {
          headerName: 'Assigned Region',
          field: 'assignedRegion',
          width: ASSIGNED_REGION_WIDTH,
          ...(canEditData
            ? {
                editable: true,
                ...getSelectCellConfig(),
                valueFormatter: (params: ValueFormatterParams) =>
                  regionsAndRegionGroups?.find(
                    (regionAndRegionGroup) =>
                      regionAndRegionGroup.trace_id === params.value,
                  )?.name ?? '',
                cellEditorParams: {
                  options: regionsAndRegionGroups?.map(
                    (regionAndRegionGroup) => ({
                      label: regionAndRegionGroup.name,
                      value: regionAndRegionGroup.trace_id,
                    }),
                  ),
                },
              }
            : { ...getGenericCellConfig() }),
        },
        {
          field: 'parameterCalculatedUnitCount',
          headerName: '# of units',
          ...getNullOrDecimalConfig(),
        },
        {
          headerName: 'Unit price',
          ...getToggleableMoneyCellConfig('unit_price', {
            cellRendererParams: { useEmDashInTotal: false },
          }),
        },
      ],
    },
    {
      headerName: 'LTD Expense',
      children: [
        {
          field: 'percentRecognized',
          headerName: '% Recognized',
          aggFunc: 'expensePercentRecognizedTotal',
          ...getPercentCellConfig({ useEmDash: false }),
        },
        {
          field: 'monthlyExpenseUnitsTotal',
          headerName: 'Total LTD units',
          ...getNullOrDecimalConfig(),
          hide: true,
        },
        {
          headerName: 'Total LTD expense',
          aggFunc: 'sum',
          ...getToggleableMoneyCellConfig('total_ltd_expense'),
        },
      ],
    },
  ];

  if (expenseData?.month_list_str_pairs) {
    const monthColumns =
      expenseData.month_list_str_pairs.flatMap((month_str: string) => {
        if (month_str.endsWith('_units')) {
          return {
            field: month_str,
            ...getPercentCompleteCellConfig(),
            ...getNullOrDecimalConfig({
              emptyValueForNodeLevel: costCategory === 'DIRECT_FEES' ? 1 : 0,
            }),
            ...(canEditData && {
              ...makeEditableIf(
                ({ data }) =>
                  data?.unitType === ActivityUnitType.PERCENT_COMPLETE,
                themeMode,
              ),
            }),
          };
        }
        return [
          {
            field: month_str,
            headerName: month_str,
            ...(canEditData && {
              ...makeEditableIf(
                ({ data, context }) =>
                  context.currencyViewMode === 'native' &&
                  data?.unitType === ActivityUnitType.AS_INVOICED,
                themeMode,
              ),
            }),
            aggFunc: 'sum',
            ...getToggleableMoneyCellConfig(month_str),
          },
        ];
      }) ?? [];

    const grouping = { headerName: 'Months', children: monthColumns };

    columnDefs.push(grouping);
  }

  return columnDefs;
}

export default function useCroExpenseGridColumnDefs(
  isOpenPeriod: boolean,
  expenseData: apiJSON | undefined,
  costCategory: CroCostCategory,
  regionsAndRegionGroups?: RegionAndRegionGroupResponse[],
): Array<CondorColDef | CondorColGroupDef> {
  const themeMode = useTheme().palette.mode;
  const canEditTrialInfo = useHasPermission(['canEditTrialInfo']);
  return useMemo(
    () =>
      processCroExpenseGridColumnDefs(
        isOpenPeriod,
        expenseData,
        costCategory,
        themeMode,
        canEditTrialInfo,
        regionsAndRegionGroups,
      ),
    [
      isOpenPeriod,
      costCategory,
      expenseData,
      regionsAndRegionGroups,
      themeMode,
      canEditTrialInfo,
    ],
  );
}
