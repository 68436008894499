import { useState } from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Popover, { type PopoverOrigin } from '@mui/material/Popover';

import IconButton, {
  type IconButtonProps,
} from 'shared/ui/icon-button/IconButton';

type Props = IconButtonProps & {
  anchorOrigin?: PopoverOrigin;
  options: Array<{
    label: string;
    onClick: () => void;
    icon?: React.ReactNode;
  }>;
  transformOrigin?: PopoverOrigin;
};

export default function KabobMenu(props: Props) {
  const { options, anchorOrigin, transformOrigin, ...buttonProps } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => setAnchorEl(null);
  return (
    <>
      <IconButton
        data-testid="KabobMenu"
        onClick={handleOpenMenu}
        {...buttonProps}
      >
        <MoreVertIcon fontSize="small" />
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        marginThreshold={0}
        open={anchorEl !== null}
        anchorOrigin={
          anchorOrigin ?? { vertical: 'bottom', horizontal: 'right' }
        }
        transformOrigin={
          transformOrigin ?? { vertical: 'top', horizontal: 'right' }
        }
        onClose={handleCloseMenu}
      >
        <Box sx={{ display: 'flex' }}>
          <List onClick={handleCloseMenu}>
            {options.map(({ label, onClick, icon }) => (
              <ListItemButton key={label} onClick={onClick}>
                <Box height={20} width={30}>
                  {icon}
                </Box>
                {label}
              </ListItemButton>
            ))}
          </List>
        </Box>
      </Popover>
    </>
  );
}
