import { useMemo } from 'react';

import InvoiceListingHeader from './components/InvoiceListingHeader';
import InvoiceListingGrid from './grids/InvoiceListingGrid';

function InvoiceListing() {
  return (
    <>
      <InvoiceListingHeader />
      <InvoiceListingGrid
        overlayNoRowsTemplate="No invoices added."
        sx={useMemo(() => ({ height: '100%' }), [])}
      />
    </>
  );
}

export default InvoiceListing;
