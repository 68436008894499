import { useMemo } from 'react';

import type { FirstDataRenderedEvent, IRowNode } from '@ag-grid-community/core';

import type { CondorGridOptions } from 'shared/components/ag-grid/types';

import type { ManageExpectedVisitRow } from 'shared/lib/types';

export default function useExpectedVisitGridOptions(
  isOpenPeriod: boolean,
  handleSelectionChange: () => void,
): CondorGridOptions<ManageExpectedVisitRow> {
  return useMemo(
    () => ({
      suppressAggFuncInHeader: true,
      rowSelection: {
        mode: 'multiRow',
        checkboxes: false,
        headerCheckbox: false,
        enableClickSelection: false,
      },
      suppressRowClickSelection: true,
      rowMultiSelectWithClick: true,
      onSelectionChanged: handleSelectionChange,
      ...(isOpenPeriod && {
        onFirstDataRendered: (params: FirstDataRenderedEvent) => {
          const nodesToSelect: IRowNode[] = [];
          params.api.forEachNode((node: IRowNode) => {
            if (node.data?.trace_id !== undefined) {
              nodesToSelect.push(node);
            }
          });
          params.api.setNodesSelected({ nodes: nodesToSelect, newValue: true });
        },
      }),
    }),
    [handleSelectionChange, isOpenPeriod],
  );
}
