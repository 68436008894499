import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';

import Button from 'shared/ui/button/Button';

import useFeatureFlag from 'shared/helpers/useFeatureFlag';
import { selectUser } from 'shared/state/slices/userSlice';

import { useGetPartnersQuery } from 'shared/api/rtkq/partners';

type Props = {
  handleClick: (partnerTraceId: string) => void;
};

function PartnerOptions(props: Props) {
  const { handleClick } = props;
  const currentUser = useSelector(selectUser);
  const partnerPermissionsEnabled = useFeatureFlag('partner_permissions');
  const { currentData: companyGroups } = useGetPartnersQuery();
  const partnerTraceId = currentUser.partner_trace_id;

  // if partnerTraceId != null, then show the partner dashboard button
  // else if currentUser.is_superuser, then show the superuser options
  // else, return undefined
  return partnerPermissionsEnabled && partnerTraceId !== null ? (
    <Button
      color="primary"
      sx={{ mb: 2 }}
      testId="partner-dashboard-btn"
      variant="outlined"
      fullWidth
      onClick={() => handleClick(partnerTraceId)}
    >
      Partner Dashboard
    </Button>
  ) : currentUser.is_superuser ? (
    <Box>
      <Divider sx={{ mb: 2 }} />
      <Typography sx={{ fontWeight: 'bold' }} variant="inherit">
        PARTNERS
      </Typography>

      <List sx={{ p: 0, my: 2 }}>
        {companyGroups?.map((group) => (
          <ListItem
            key={group.trace_id}
            sx={{ cursor: 'pointer', p: 0, my: 1 }}
            onClick={() => handleClick(group.trace_id)}
          >
            <Typography variant="inherit">{group.name}</Typography>
          </ListItem>
        ))}
      </List>
    </Box>
  ) : undefined;
}

export default PartnerOptions;
