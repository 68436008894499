import useTrialRecord from 'accruals/pages/trial-settings/hooks/useTrialRecord';
import withPeriodSpecificRecordWrapper from 'shared/lib/periods/withPeriodSpecificRecordWrapper';
import RecordViewer, {
  RecordStyle,
} from 'shared/lib/record-viewer/RecordViewer';
import { PeriodRecordBlobType } from 'shared/lib/types';

function TrialRecordCardContents() {
  const record = useTrialRecord(true);
  return <RecordViewer record={record} recordStyle={RecordStyle.STACKED} />;
}

export default withPeriodSpecificRecordWrapper(
  TrialRecordCardContents,
  PeriodRecordBlobType.TRIAL,
);
