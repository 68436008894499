import { useEffect, useRef } from 'react';

import type { SxProps } from '@mui/material/styles';

import type { AgGridRef } from 'shared/components/ag-grid/CondorAgGrid';
import CondorAgGrid from 'shared/components/ag-grid/CondorAgGrid';
import useGridColDefs from 'shared/components/ag-grid/hooks/useGridColDefs';
import useGridOptions from 'shared/components/ag-grid/hooks/useGridOptions';
import useLockedGridStatus from 'shared/components/lock-grid/useLockedGridStatus';

import useAccrualsGridOptions from 'accruals/pages/analytics/hooks/useAccrualsGridOptions';
import useAccrualsRows from 'accruals/pages/analytics/hooks/useAccrualsRows';
import withPeriodSpecificGridWrapper from 'shared/lib/periods/withPeriodSpecificGridWrapper';
import type { AccrualsGridRow } from 'shared/lib/types';
import { LockGrid, PeriodGridBlobType } from 'shared/lib/types';

import useAccrualsColumnDefs from '../hooks/useAccrualsColumnDefs';

type Props = { overlayNoRowsTemplate?: string; sx?: SxProps };

function AccrualsGrid(props: Props) {
  const { sx, overlayNoRowsTemplate } = props;
  const gridRef = useRef<AgGridRef<AccrualsGridRow>>();

  const isGridLocked = useLockedGridStatus(LockGrid.ACCRUALS_GRID);
  const columnDefs = useGridColDefs(
    useAccrualsColumnDefs,
    [isGridLocked],
    isGridLocked,
  );
  const gridOptions = useGridOptions(useAccrualsGridOptions);
  const rowData = useAccrualsRows();

  // biome-ignore lint/correctness/useExhaustiveDependencies: if the data changes or the state of the locked grid changes, we want to redraw the grid
  useEffect(() => {
    gridRef.current?.api?.redrawRows();
  }, [rowData, isGridLocked]);

  return (
    <CondorAgGrid
      ref={gridRef}
      columnDefs={columnDefs}
      gridOptions={gridOptions}
      overlayNoRowsTemplate={overlayNoRowsTemplate}
      rowData={rowData}
      sx={sx}
    />
  );
}

export default withPeriodSpecificGridWrapper(
  AccrualsGrid,
  PeriodGridBlobType.ACCRUALS,
);
