import { useEffect } from 'react';

import Box from '@mui/system/Box';
import { useDispatch } from 'react-redux';

import type { CommentLocationType } from 'shared/lib/types';
import { updateHeader, updateTitle } from 'shared/state/slices/commentSlice';

import CommentsContainer from './CommentsContainer';
import CommentsPopper from './CommentsPopper';
import CommentsWidgetIcon from './CommentsWidgetIcon';
import useCommentsVisibility from './hooks/useCommentsVisibility';

type Props = {
  commentsForPage?: boolean;
  header?: string;
  location: CommentLocationType;
  locationSlug?: string;
  title?: string;
};

function CommentsWidget(props: Props) {
  const {
    location,
    commentsForPage,
    locationSlug = '',
    header = 'Comments',
    title = null,
  } = props;
  const placement = commentsForPage ? 'top-end' : 'auto-start';
  const dispatch = useDispatch();
  const { open, onClickComments } = useCommentsVisibility(
    location,
    locationSlug,
  );

  useEffect(() => {
    if (!open) {
      return;
    }

    dispatch(updateHeader({ header }));
    dispatch(updateTitle({ title }));
  }, [open]);

  return (
    <CommentsPopper
      arrow={!commentsForPage}
      open={open}
      placement={placement}
      content={
        <CommentsContainer
          location={location}
          locationSlug={locationSlug}
          onClose={onClickComments}
        />
      }
    >
      <Box
        sx={
          commentsForPage
            ? { position: 'fixed', right: '35px', bottom: '25px' }
            : null
        }
      >
        <CommentsWidgetIcon
          location={location}
          locationSlug={locationSlug}
          onClick={onClickComments}
        />
      </Box>
    </CommentsPopper>
  );
}

export default CommentsWidget;
