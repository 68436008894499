import { useEffect, useState } from 'react';

import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined';
import { useLocation } from 'react-router-dom';

import IconButton from 'shared/ui/icon-button/IconButton';

import TrialUploadsPopper from './TrialUploadsPopper';
import TrialUploadsContent from './TrialUploadsContent';

function TrialUploads() {
  const [showFrequentUploadsDropdown, setShowFrequentUploadsDropdown] =
    useState(false);
  const location = useLocation();

  useEffect(() => {
    setShowFrequentUploadsDropdown(false);
  }, [location]);

  return (
    <TrialUploadsPopper
      content={<TrialUploadsContent />}
      open={showFrequentUploadsDropdown}
    >
      <span>
        <IconButton
          onClick={() =>
            setShowFrequentUploadsDropdown((prevState) => !prevState)
          }
        >
          <UploadOutlinedIcon />
        </IconButton>
      </span>
    </TrialUploadsPopper>
  );
}

export default TrialUploads;
