import type { MouseEvent } from 'react';
import { useState } from 'react';

import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

import EmailImage from 'shared/assets/images/email.svg?react';
import FaqImage from 'shared/assets/images/faq.svg?react';
import SubmitImage from 'shared/assets/images/submit.svg?react';
import IconButton from 'shared/ui/icon-button/IconButton';

const BUTTONS = [
  {
    label: 'FAQ',
    image: <FaqImage />,
    href: 'https://condorsoftware.zendesk.com/hc/en-us',
  },
  {
    label: 'Submit a request',
    image: <SubmitImage />,
    href: 'https://condorsoftware.zendesk.com/hc/en-us/requests/new',
  },
  {
    label: 'Email us',
    image: <EmailImage />,
    href: 'mailto:support@condorsoftware.com',
  },
];

function HelpDropdown() {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleOnClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(anchorEl === null ? event.currentTarget : null);
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '16', mx: 1 }}>
        <IconButton data-testid="help-button" onClick={handleOnClick}>
          <QuestionMarkIcon />
        </IconButton>
      </Box>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={anchorEl !== null}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => setAnchorEl(null)}
      >
        <Box sx={{ p: 2, width: 220 }}>
          {BUTTONS.map((button) => (
            <Box
              key={button.label}
              sx={{
                backgroundColor: 'grey.100',
                mb: 1,
                '&:last-of-type': {
                  mb: 0,
                },
              }}
            >
              <Box
                component={Link}
                href={button.href}
                target="_blank"
                sx={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  px: 2,
                  py: 1,
                  textDecoration: 'none',
                }}
              >
                {button.image}
                <Typography color="textPrimary" sx={{ ml: 1 }} variant="body1">
                  {button.label}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Popover>
    </>
  );
}

export default HelpDropdown;
