import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';

import DashboardCard from 'shared/ui/dashboard-card/DashboardCard';

import AssumptionRow from './assumptions-tab/AssumptionRow';
import useContracts from './assumptions-tab/hooks/useContracts';

function AssumptionsTab() {
  const { loading, contracts } = useContracts();

  return loading ? (
    <DashboardCard sx={{ overflowY: 'auto' }} title="Assumptions">
      <Skeleton height={400} variant="rectangular" width="100%" />
    </DashboardCard>
  ) : (
    <Grid spacing={4} container>
      {contracts
        ?.filter((cv) => cv.vendor_type === 'CRO')
        .map((contractVersion) => (
          <Grid key={contractVersion.trace_id} xs={12} item>
            <DashboardCard
              sx={{ overflowY: 'auto' }}
              title={`${contractVersion.vendor_type} ${contractVersion.vendor_name} assumptions`}
            >
              <AssumptionRow contractVersion={contractVersion} />
            </DashboardCard>
          </Grid>
        ))}
    </Grid>
  );
}

export default AssumptionsTab;
