import type { ReactElement } from 'react';

import type { RecordStyle } from 'shared/lib/record-viewer/RecordViewer';
import RecordViewer from 'shared/lib/record-viewer/RecordViewer';
import type {
  KeyValueRow,
  PeriodRecordBlobType,
  TraceId,
} from 'shared/lib/types';

import useRecordBlobDataLoader from './hooks/useRecordBlobDataLoader';

type Props = {
  parentMenuItem?: TraceId;
  recordStyle: RecordStyle;
  savedObjectType: PeriodRecordBlobType;
};

export default function PeriodRecordBlobViewer(props: Props): ReactElement {
  const { savedObjectType, parentMenuItem, recordStyle } = props;
  const blobData = useRecordBlobDataLoader<KeyValueRow[]>(
    savedObjectType,
    parentMenuItem,
  );

  return <RecordViewer record={blobData} recordStyle={recordStyle} />;
}
