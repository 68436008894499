import { useMemo } from 'react';

import type { CondorColDef } from 'shared/components/ag-grid/types';

import useFeatureFlag from 'shared/helpers/useFeatureFlag';
import useHasPermission from 'shared/lib/permissions/useHasPermission';

import GlAccountCellRenderer from '../grids/GlAccountCellRenderer';

export default function useGlAccountColumnDefs(
  isOpenPeriod: boolean,
): CondorColDef[] {
  const canEditCompanyLevelInfo = useHasPermission(['canEditCompanyLevelInfo']);
  const canDeleteCompanyLevelInfo = useHasPermission([
    'canDeleteCompanyLevelInfo',
  ]);
  const isSoftDeleteEnabled = useFeatureFlag('soft_delete');

  return useMemo(
    () => [
      { headerName: 'G/L account number', field: 'accountNumber', flex: 1 },
      { headerName: 'Account type', field: 'accountType', flex: 1 },
      { headerName: 'Description', field: 'description', flex: 1 },
      { headerName: 'Trials', field: 'trials', flex: 1 },
      ...(isOpenPeriod &&
      (canEditCompanyLevelInfo ||
        (isSoftDeleteEnabled && canDeleteCompanyLevelInfo))
        ? [
            {
              headerName: '',
              field: 'tools',
              width: 50,
              sortable: false,
              resizable: false,
              suppressHeaderMenuButton: true,
              cellClass: 'ag-cell-button',
              cellRenderer: GlAccountCellRenderer,
            },
          ]
        : []),
    ],
    [
      isOpenPeriod,
      canEditCompanyLevelInfo,
      isSoftDeleteEnabled,
      canDeleteCompanyLevelInfo,
    ],
  );
}
