import { useMemo } from 'react';

import { useParams } from 'react-router-dom';

import NavigationTabs from 'shared/components/tabs/NavigationTabs';

import { getTrialFilesNavigation } from 'routes';
import PageContentLoadingContainer from 'shared/containers/page-content-loading-container/PageContentLoadingContainer';

import withAccrualPageRequiredData from 'accruals/api/hocs/withAccrualPageRequiredData';

import PeriodFilesGrid from './grids/PeriodFilesGrid';
import TrialFilesGrid from './grids/TrialFilesGrid';

function TrialFilesPage() {
  const { activeTab } = useParams();

  const TABS = [
    { key: 'period', devLabel: 'FILES', label: 'Period Files' },
    { key: 'contract', devLabel: 'FILES', label: 'Contract Files' },
  ];

  const gridSx = useMemo(() => ({ height: '100%' }), []);

  return (
    <PageContentLoadingContainer
      tabs={
        <NavigationTabs
          activeTab={activeTab}
          generateUrl={getTrialFilesNavigation}
          tabs={TABS}
        />
      }
    >
      {activeTab === 'period' && (
        <PeriodFilesGrid overlayNoRowsTemplate="No files." sx={gridSx} />
      )}
      {activeTab === 'contract' && (
        <TrialFilesGrid overlayNoRowsTemplate="No files." sx={gridSx} />
      )}
    </PageContentLoadingContainer>
  );
}

export default withAccrualPageRequiredData(TrialFilesPage);
