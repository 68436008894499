import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';

type Props = {
  children: React.ReactElement;
  helperText?: string;
  htmlFor?: string;
  label: string;
  required?: boolean;
};

export default function HorizontalInput(props: Props) {
  const { label, helperText, children, htmlFor, required } = props;
  return (
    <FormControl required={required} fullWidth>
      <Grid container>
        <Grid xs={6} item>
          <InputLabel
            htmlFor={htmlFor}
            shrink={false}
            sx={{
              alignItems: 'flex-start',
              display: 'flex',
              lineHeight: 'normal',
              position: 'relative',
              transform: 'none',
              mt: 1,
            }}
            disableAnimation
          >
            <Typography color="text.primary" variant="subtitle2">
              {label}
            </Typography>
          </InputLabel>
          {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
        </Grid>
        <Grid xs={6} item>
          {children}
        </Grid>
      </Grid>
    </FormControl>
  );
}
