import useFeatureFlag from 'shared/helpers/useFeatureFlag';
import useHasPermission from 'shared/lib/permissions/useHasPermission';

function useShowRowMenuColumn() {
  const isSoftDeleteEnabled = useFeatureFlag('soft_delete');
  const canEditTrialInfo = useHasPermission(['canEditTrialInfo']);
  const canDeleteTrialInfo = useHasPermission(['canDeleteTrialInfo']);

  const showDeleteMenu = isSoftDeleteEnabled && canDeleteTrialInfo;
  return showDeleteMenu || canEditTrialInfo;
}

export default useShowRowMenuColumn;
