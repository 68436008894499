import type { Dispatch, SetStateAction } from 'react';

import Box from '@mui/material/Box';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';
import type { SxProps } from '@mui/material/styles';

type Props = {
  modes?: CurrencyViewMode[];
  setValue: Dispatch<SetStateAction<CurrencyViewMode>>;
  sx?: SxProps;
  testId: string;
  value: CurrencyViewMode;
};

const FULL_MODE_NAMES: Record<CurrencyViewMode, string> = {
  native: 'native cro',
  site: 'native site',
  trial: 'trial',
};

function CurrencyToggleGroup(props: Props) {
  const { modes = ['native', 'trial'], setValue, testId, value, sx } = props;

  function handleChange(
    _event: React.MouseEvent<HTMLElement>,
    updatedValue: CurrencyViewMode | null,
  ): void {
    if (updatedValue !== null) {
      setValue(updatedValue);
    }
  }

  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="row"
      gap={1}
      sx={{ ml: 'auto', ...sx }}
    >
      <Typography variant="body2">Currency</Typography>
      <ToggleButtonGroup
        data-testid={testId}
        orientation="horizontal"
        value={value}
        exclusive
        onChange={handleChange}
      >
        {modes.map((mode) => (
          <ToggleButton key={mode} size="small" value={mode}>
            {modes.length > 2 ? FULL_MODE_NAMES[mode] : mode}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Box>
  );
}

export type CurrencyViewMode = 'native' | 'site' | 'trial';

export default CurrencyToggleGroup;
