import { type ReactElement, useCallback, useRef } from 'react';

import type { CellValueChangedEvent } from '@ag-grid-community/core';
import type { SxProps } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import { format } from 'date-fns/format';

import type { AgGridRef } from 'shared/components/ag-grid/CondorAgGrid';
import CondorAgGrid from 'shared/components/ag-grid/CondorAgGrid';
import useGridColDefs from 'shared/components/ag-grid/hooks/useGridColDefs';
import useGridOptions from 'shared/components/ag-grid/hooks/useGridOptions';

import useHistoricalValuesColumnDefs from 'accruals/pages/trial/hooks/useHistoricalValuesColumnDefs';
import useHistoricalValuesGridOptions from 'accruals/pages/trial/hooks/useHistoricalValuesGridOptions';
import useHistoricalValuesRows from 'accruals/pages/trial/hooks/useHistoricalValuesRows';
import { currencyToNumberFormatter } from 'formatters';
import withPeriodSpecificGenericWrapper from 'shared/lib/periods/withPeriodSpecificGenericWrapper';
import type { HistoricalValuesExpenseUpsertRecord } from 'shared/lib/types';

import { useUpsertHistoricalExpensesMutation } from 'shared/api/rtkq/historicalexpenses';

type Props = {
  overlayNoRowsTemplate?: string;
  sx?: SxProps;
};

const DEFAULT_REF_STATE = {
  hasStartPaste: false,
  cellValues: [] as CellValueChangedEvent[],
};

function HistoricalValuesGrid(props: Props): ReactElement {
  const { overlayNoRowsTemplate, sx } = props;
  const gridRef = useRef<AgGridRef<HistoricalValuesExpenseUpsertRecord>>(null);

  const refState = useRef(DEFAULT_REF_STATE);
  const columnDefs = useGridColDefs(useHistoricalValuesColumnDefs);
  const gridOptions = useGridOptions(useHistoricalValuesGridOptions);
  const rowData = useHistoricalValuesRows();
  const [upsertHistoricalExpenses] = useUpsertHistoricalExpensesMutation();

  const flushToUpsert = useCallback(() => {
    void (async () => {
      const expenses = refState.current.cellValues.reduce<
        HistoricalValuesExpenseUpsertRecord[]
      >((acc, { colDef, data, newValue }) => {
        let cost = currencyToNumberFormatter(newValue);
        if (Number.isNaN(cost)) {
          cost = 0;
        }
        const date = new Date(colDef.colId ?? '');
        const lastDayOfMonth = new Date(
          date.getFullYear(),
          date.getMonth() + 1,
          0,
        );

        acc.push({
          contract_container: data.contract_container,
          cost_category: data.cost_category,
          end_date: format(lastDayOfMonth, 'yyyy-LL-dd'),
          amount: cost,
        });

        return acc;
      }, []);

      try {
        gridRef.current?.api?.setGridOption('loading', true);
        await upsertHistoricalExpenses({ expenses });
        gridRef.current?.api?.setGridOption('loading', false);
      } catch (error) {
        Sentry.captureException(error);
      }

      refState.current.cellValues = [];
    })();
  }, [upsertHistoricalExpenses]);

  const onPasteStart = useCallback(() => {
    refState.current.hasStartPaste = true;
  }, []);

  const onPasteEnd = useCallback(() => {
    flushToUpsert();
    refState.current.hasStartPaste = false;
  }, [flushToUpsert]);

  const onCellValueChanged = useCallback(
    (data: CellValueChangedEvent) => {
      refState.current.cellValues.push(data);
      if (!refState.current.hasStartPaste) {
        flushToUpsert();
      }
    },
    [flushToUpsert],
  );

  return (
    <CondorAgGrid
      ref={gridRef}
      columnDefs={columnDefs}
      gridOptions={gridOptions}
      overlayNoRowsTemplate={overlayNoRowsTemplate}
      rowData={rowData}
      sx={sx}
      onCellValueChanged={onCellValueChanged}
      onPasteEnd={onPasteEnd}
      onPasteStart={onPasteStart}
    />
  );
}

// this grid doesn't get "periodified" so should be just hidden for closed periods
export default withPeriodSpecificGenericWrapper(HistoricalValuesGrid);
