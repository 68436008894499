import { useState } from 'react';

import Edit from '@mui/icons-material/EditOutlined';

import DashboardButton from 'shared/ui/dashboard-button/DashboardButton';
import DashboardCard from 'shared/ui/dashboard-card/DashboardCard';

import TrialSetupWizard from 'accruals/wizards/trial-setup/TrialSetupWizard';
import withPeriodSpecificGenericWrapper from 'shared/lib/periods/withPeriodSpecificGenericWrapper';
import HasPermission from 'shared/lib/permissions/HasPermission';
import { RecordStyle } from 'shared/lib/record-viewer/RecordViewer';

import TrialRecordCardContents from './TrialRecordCardContents';

type Props = { onEditButtonClick?: () => void };

function TitleAction(props: Props) {
  const { onEditButtonClick } = props;

  return (
    <DashboardButton
      startIcon={<Edit />}
      testId="edit_trial"
      onClick={onEditButtonClick}
    >
      Edit
    </DashboardButton>
  );
}

const PeriodAwareTitleAction = withPeriodSpecificGenericWrapper(TitleAction);

export default function TrialRecordCard() {
  const [editModalOpen, setEditModalOpen] = useState(false);

  return (
    <>
      <DashboardCard
        title="Trial Record"
        titleAction={
          <HasPermission permissions={['canEditTrialInfo']}>
            <PeriodAwareTitleAction
              onEditButtonClick={() => setEditModalOpen(true)}
            />
          </HasPermission>
        }
        showDivider
      >
        <TrialRecordCardContents recordStyle={RecordStyle.STACKED} />
      </DashboardCard>
      {editModalOpen && (
        <TrialSetupWizard onClose={() => setEditModalOpen(false)} />
      )}
    </>
  );
}
