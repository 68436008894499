import { useSelector } from 'react-redux';

import useFeatureFlag from 'shared/helpers/useFeatureFlag';
import type { UserPermissions } from 'shared/lib/types';
import { missingTrial, selectTrial } from 'shared/state/slices/trialSlice';
import {
  selectCurrentUserPermissions,
  selectUserCanEdit,
  selectUserTraceId,
} from 'shared/state/slices/userSlice';

// force_allow means regardless of what their assigned permissions are, they can do this for this trial
// force_deny means regardless of what their assigned permissions are, they can't do this for this trial
// not_relevant means we honor their assigned permissions regardless if they are the assigned preparer or reviewer
type UserPermissionMap = Record<
  keyof UserPermissions,
  'force_allow' | 'force_deny' | 'not_relevant'
>;

// For the specific trial that a user is in, they might get "hidden" or "special" permissions
// where we specifically override what they can usually do as they are acting in the role of
// "preparer" or "reviewer". As that can change per trial, we need to check on the FE.
const PREPARER_SPECIAL_PERMISSIONS: UserPermissionMap = {
  canEditCompanyUsersAndPermissions: 'not_relevant',
  canEditCompanySettings: 'not_relevant',
  canEditCompanyLevelInfo: 'force_deny',
  canDeleteCompanyLevelInfo: 'force_deny',
  canSignoffAsPreparer: 'force_allow',
  canSignoffAsReviewer: 'force_deny',
  canManageTrialRoles: 'not_relevant',
  canEditTrialInfo: 'force_allow',
  canDeleteTrialInfo: 'force_allow',
  canOpenPeriod: 'force_allow',
  canClosePeriod: 'force_deny',
  canReopenPeriod: 'force_deny',
  canAddComments: 'force_allow',
  canEditHistoricValues: 'force_deny',
  canAccessForecasting: 'not_relevant',
  canCreateAndEditForecasting: 'not_relevant',
  canEditPeriodClosedValues: 'force_deny',
  canLockGrids: 'not_relevant',
  canEditData: 'not_relevant',
};

const REVIEWER_SPECIAL_PERMISSIONS: UserPermissionMap = {
  canEditCompanyUsersAndPermissions: 'not_relevant',
  canEditCompanySettings: 'not_relevant',
  canEditCompanyLevelInfo: 'force_deny',
  canDeleteCompanyLevelInfo: 'force_deny',
  canSignoffAsPreparer: 'force_deny',
  canSignoffAsReviewer: 'force_allow',
  canManageTrialRoles: 'not_relevant',
  canEditTrialInfo: 'force_deny',
  canDeleteTrialInfo: 'force_deny',
  canOpenPeriod: 'force_deny',
  canClosePeriod: 'force_allow',
  canReopenPeriod: 'force_allow',
  canAddComments: 'force_allow',
  canEditHistoricValues: 'force_deny',
  canAccessForecasting: 'not_relevant',
  canCreateAndEditForecasting: 'not_relevant',
  canEditPeriodClosedValues: 'force_deny',
  canLockGrids: 'not_relevant',
  canEditData: 'not_relevant',
};

export default function useHasPermission(
  permissions: Array<keyof UserPermissions>,
  separator: 'AND' | 'OR' = 'AND',
) {
  const usersAndPermissionsFeatureFlag = useFeatureFlag(
    'users_and_permissions',
  );
  const trial = useSelector(selectTrial);
  const currentUserTraceId = useSelector(selectUserTraceId);
  const canEditData = useSelector(selectUserCanEdit);
  const currentUserPermissions = useSelector(selectCurrentUserPermissions);

  function checkTrialRolePermissions(permissionMap: UserPermissionMap) {
    return permissions.every(
      (permission) =>
        permissionMap[permission] === 'force_allow' ||
        (permissionMap[permission] === 'not_relevant' &&
          currentUserPermissions[permission]),
    );
  }

  function hasPermission(): boolean {
    if (trial !== missingTrial) {
      if (currentUserTraceId === trial.preparer?.trace_id) {
        return checkTrialRolePermissions(PREPARER_SPECIAL_PERMISSIONS);
      }
      if (currentUserTraceId === trial.reviewer?.trace_id) {
        return checkTrialRolePermissions(REVIEWER_SPECIAL_PERMISSIONS);
      }
    }

    return separator === 'OR'
      ? permissions.some((permission) => currentUserPermissions[permission])
      : permissions.every((permission) => currentUserPermissions[permission]);
  }

  return usersAndPermissionsFeatureFlag
    ? hasPermission()
    : (canEditData ?? false);
}
