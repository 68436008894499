import type { CustomCellEditorProps } from '@ag-grid-community/react';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import type { Theme } from '@mui/material/styles/createTheme';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import type { PickerChangeHandlerContext } from '@mui/x-date-pickers/models/pickers';
import type { DateValidationError } from '@mui/x-date-pickers/models/validation';
import { format } from 'date-fns/format';
import { parse } from 'date-fns/parse';

import { textFieldClasses } from 'shared/components/text-field/CondorTextField';

type Props = CustomCellEditorProps & { dateFormat?: string };

function AgGridDateCellEditor(props: Props) {
  const { api, dateFormat = 'yyyy-MM-dd', value, onValueChange } = props;

  // this is called when the text changes in the input field. ag-grid DOES
  // handle this input correctly, so we don't need to manually tell it to stop editing
  const handleOnChange = (
    newValue: Date | null,
    context: PickerChangeHandlerContext<DateValidationError>,
  ) => {
    if (context.validationError) {
      return;
    }

    onValueChange(newValue ? format(newValue, dateFormat) : '');
  };

  // this is called when the date changes in the popover. ag-grid does NOT
  // handle this input correctly, so we need to manually tell it to stop editing
  const handleOnAccept = (newValue: Date | null) => {
    onValueChange(newValue ? format(newValue, dateFormat) : '');
    api.stopEditing();
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        format={dateFormat}
        slots={{ openPickerIcon: CalendarMonthOutlinedIcon }}
        slotProps={{
          openPickerButton: {
            sx: {
              p: '3px',
              '& > svg': { width: '18px', height: '18px', fontSize: '18px' },
              mr: '4px',
            },
          },
          openPickerIcon: {
            sx: {
              height: '12px',
              width: '12px',
              color: (theme: Theme) => theme.palette.grey[500],
            },
          },
          textField: {
            size: 'small',
            variant: 'standard',
            fullWidth: true,
            sx: {
              height: '100%',
              [`&.${textFieldClasses.root}`]: { height: 'inherit' },
              [`&.${textFieldClasses.root} input`]: {
                textAlign: 'right',
                fontSize: '12px !important',
              },
            },
          },
        }}
        value={
          typeof value === 'string'
            ? parse(value, dateFormat, new Date())
            : new Date()
        }
        onAccept={handleOnAccept}
        onChange={handleOnChange}
      />
    </LocalizationProvider>
  );
}

export default AgGridDateCellEditor;
