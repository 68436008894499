import type { CurrencyCode } from 'currencies';
import { currencyFormatter } from 'formatters';

import type { TabularDataCellTextProps } from './TabularDataCellText';
import TabularDataCellText from './TabularDataCellText';

function TabularDataCellCurrency(
  props: TabularDataCellTextProps & { currency: CurrencyCode },
) {
  const { value, currency, ...other } = props;
  return (
    <TabularDataCellText
      {...other}
      value={currencyFormatter(value, currency)}
    />
  );
}

export default TabularDataCellCurrency;
