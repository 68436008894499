import type { ICellRendererParams } from '@ag-grid-community/core';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { humanize } from 'shared/helpers/helpers';
import type { AuditLogResponse } from 'shared/lib/types';

import { getRelevantChanges } from './utils';

function AgGridRecordCellRenderer(
  params: ICellRendererParams<AuditLogResponse>,
) {
  const { data, value } = params;

  const validChanges = getRelevantChanges(value);
  const isUpdate = data?.action === 1;

  return (
    <>
      {validChanges.map((validChange) => {
        // if this is an update, always show the previous and new values
        const hasPreviousValue =
          validChange.previousValue !== undefined || isUpdate;
        const hasNewValue = validChange.newValue !== undefined || isUpdate;

        return (
          <Box
            key={
              validChange.key ??
              validChange.newValue ??
              validChange.previousValue
            }
            sx={{ paddingBottom: 1 }}
          >
            {!!validChange.key && (
              <Typography variant="body2">
                {humanize(validChange.key)}
              </Typography>
            )}
            <Typography variant="body1">
              {hasPreviousValue
                ? `${
                    validChange.previousValue === '' ||
                    (validChange.previousValue == null && isUpdate)
                      ? '[blank]'
                      : validChange.previousValue
                  }${hasNewValue ? ' → ' : ''}`
                : ''}
              {hasNewValue
                ? validChange.newValue === '' ||
                  (validChange.newValue == null && isUpdate)
                  ? '[blank]'
                  : validChange.newValue
                : ''}
            </Typography>
          </Box>
        );
      })}
    </>
  );
}

export default AgGridRecordCellRenderer;
