/*
██████╗░██╗░░░░░███████╗░█████╗░░██████╗███████╗  ██████╗░███████╗░█████╗░██████╗░
██╔══██╗██║░░░░░██╔════╝██╔══██╗██╔════╝██╔════╝  ██╔══██╗██╔════╝██╔══██╗██╔══██╗
██████╔╝██║░░░░░█████╗░░███████║╚█████╗░█████╗░░  ██████╔╝█████╗░░███████║██║░░██║
██╔═══╝░██║░░░░░██╔══╝░░██╔══██║░╚═══██╗██╔══╝░░  ██╔══██╗██╔══╝░░██╔══██║██║░░██║
██║░░░░░███████╗███████╗██║░░██║██████╔╝███████╗  ██║░░██║███████╗██║░░██║██████╔╝
╚═╝░░░░░╚══════╝╚══════╝╚═╝░░╚═╝╚═════╝░╚══════╝  ╚═╝░░╚═╝╚══════╝╚═╝░░╚═╝╚═════╝░

The only thing that should go inside this file are things that ONLY need the company 
loaded and ready. If you add anything for other parts of the app, like accruals, this 
will likely not work as expected and possibly break our menu structure.

This is a XXXLayout instead of a container as we only want this to render once, and not unmount
when we stay within the company pages so we don't cause the menu to flicker.

If you have any questions, please ask in #engineering
*/

import { Outlet } from 'react-router-dom';

import CompanySidebar from 'company/components/sidebar/CompanySidebar';

import PageContainer from 'shared/containers/page-container/PageContainer';
import PageContentContainer from 'shared/containers/page-content-container/PageContentContainer';

import withCompanyRequired from 'shared/api/hocs/withCompanyRequired';

function CompanyLayout() {
  return (
    <PageContainer>
      <CompanySidebar />
      <PageContentContainer>
        <Outlet />
      </PageContentContainer>
    </PageContainer>
  );
}

export default withCompanyRequired(CompanyLayout);
