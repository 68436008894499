import '@lottiefiles/lottie-player'; // eslint-disable-line import/no-unused-modules -- this is adding it globally to the window
import 'normalize.css/normalize.css';

import 'index.scss';
import { darkPalette, lightPalette } from 'palettes';
import loading_dark from 'shared/lotties/loading_dark.json';
import loading_light from 'shared/lotties/loading_light.json';

// biome-ignore lint/style/noNonNullAssertion: this is on index.html. it's guaranteed to be there
const loadingContainer = document.getElementById('loading')!;

window.hidePageLoading = () => {
  if (loadingContainer.children.length > 0) {
    loadingContainer.innerHTML = '';
  }
};

window.showPageLoading = () => {
  const theme =
    (localStorage.getItem('theme') as 'dark' | 'light' | null) ?? 'light';

  loadingContainer.style.backgroundColor =
    theme === 'dark'
      ? darkPalette.background.default
      : lightPalette.background.default;

  if (loadingContainer.children.length === 0) {
    const container = document.createElement('div');
    container.style.width = '100px';
    container.style.height = '100px';
    container.innerHTML = `<lottie-player src='${JSON.stringify(theme === 'light' ? loading_light : loading_dark)}' autoplay loop mode="normal" speed="1" width="150" height="150"></lottie-player>`;
    loadingContainer.appendChild(container);
  }
};

window.showPageLoading();
