import Typography from '@mui/material/Typography';

import FlexGridContainer from 'shared/containers/flex-grid-container/FlexGridContainer';
import PageContentLoadingContainer from 'shared/containers/page-content-loading-container/PageContentLoadingContainer';
import { RecordStyle } from 'shared/lib/record-viewer/RecordViewer';

import VarianceThresholdForm from './VarianceThresholdForm';

function VarianceThreshold() {
  return (
    <PageContentLoadingContainer>
      <FlexGridContainer>
        <Typography color="primary.main" variant="h4">
          Variance Threshold
        </Typography>
        <Typography fontWeight="bold">
          The variance threshold is used to flag variances when comparing these
          values:
        </Typography>
        <ul style={{ marginTop: 0 }}>
          <li>
            The variance between a vendor-reported value and the current
            contract&apos;s value
          </li>
          <li>
            The variance between amendment-in-progress value and the current
            contract&apos;s value
          </li>
          <li>
            The variance between prior quarter and current quarter expenses and
            accruals when viewing the balance sheet flux
          </li>
        </ul>
        <VarianceThresholdForm recordStyle={RecordStyle.HORIZONTAL} />
      </FlexGridContainer>
    </PageContentLoadingContainer>
  );
}

export default VarianceThreshold;
