import type { ReactNode } from 'react';

import Box from '@mui/material/Box';

import PartnerAppToolbar from 'shared/components/app-toolbar/PartnerAppToolbar';

type Props = {
  children: ReactNode;
};

function PartnerPageContainer(props: Props) {
  const { children } = props;

  return (
    <>
      <PartnerAppToolbar />
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexFlow: 'row',
          width: '100%',
          minHeight: 0,
          overflow: 'auto',
        }}
      >
        {children}
      </Box>
    </>
  );
}

export default PartnerPageContainer;
