import UserManagementHeader from 'partner/components/header/UserManagementHeader';

function UserManagement() {
  return (
    <>
      <UserManagementHeader />
      Partner Main Body
    </>
  );
}

export default UserManagement;
