import Grid from '@mui/material/Grid';

import PageContentLoadingContainer from 'shared/containers/page-content-loading-container/PageContentLoadingContainer';

import withAccrualPageRequiredData from 'accruals/api/hocs/withAccrualPageRequiredData';

import TrialRecordCard from './components/TrialRecordCard';

function TrialSettingsPage() {
  return (
    <PageContentLoadingContainer>
      <Grid spacing={3} container>
        <Grid xs={4} item>
          <TrialRecordCard />
        </Grid>
      </Grid>
    </PageContentLoadingContainer>
  );
}

export default withAccrualPageRequiredData(TrialSettingsPage);
