import { StrictMode, useCallback } from 'react';

import type { AppState } from '@auth0/auth0-react';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { Provider } from 'react-redux';
import { BrowserRouter, useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

import AppRoutes from 'AppRoutes';
import config from 'config';
import security from 'security';
import AppContainer from 'shared/containers/app-container/AppContainer';
import store from 'shared/state/store';

// This is here solely to take the `useAuth0` hook out of the normal render tree
function SetAccessToken() {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();

  // https://github.com/reduxjs/redux-toolkit/issues/1331#issuecomment-1080901326
  security.setGetAccessTokenSilently(getAccessTokenSilently);
  security.setGetAccessTokenWithPopup(getAccessTokenWithPopup);

  return null;
}

function AppWithAuthentication() {
  return (
    <>
      <SetAccessToken />
      <AppContainer>
        <AppRoutes />
      </AppContainer>
    </>
  );
}

/**
 * This should only ever be used for testing on localhost
 * when you don't have an internet connection.
 */
function AppWithoutAuthentication() {
  security.setGetAccessTokenSilently(async (_options?) => {
    await Promise.resolve();
    return '';
  });
  security.setGetAccessTokenWithPopup(async (_options?, _config?) => {
    await Promise.resolve();
    return undefined;
  });

  return (
    <AppContainer dangerouslyIgnoreAuth_DO_NOT_USE_OR_YOU_WILL_BE_FIRED>
      <AppRoutes />
    </AppContainer>
  );
}

function InternalApp() {
  const navigate = useNavigate();

  const onRedirectCallback = useCallback(
    (appState: AppState | undefined) => {
      if (appState?.returnTo) {
        navigate(appState.returnTo);
      }
    },
    [navigate],
  );

  return (
    <Auth0Provider
      authorizationParams={{ redirect_uri: window.location.origin }}
      cacheLocation={config.IS_CYPRESS_ENVIRONMENT ? 'localstorage' : undefined}
      clientId={config.VITE_APP_AUTH0_CLIENT_ID}
      domain={config.VITE_APP_AUTH0_DOMAIN}
      useRefreshTokensFallback={config.IS_CYPRESS_ENVIRONMENT}
      onRedirectCallback={onRedirectCallback}
    >
      {config.VITE_APP_DISABLE_AUTH ? (
        <AppWithoutAuthentication />
      ) : (
        <AppWithAuthentication />
      )}
    </Auth0Provider>
  );
}

function App() {
  return (
    <StrictMode>
      <BrowserRouter>
        <Provider store={store}>
          <InternalApp />
        </Provider>
      </BrowserRouter>
    </StrictMode>
  );
}

export default App;
