import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import { useSelector } from 'react-redux';

import * as routes from 'routes';
import useFeatureFlag from 'shared/helpers/useFeatureFlag';
import SidebarContainer, { ICON_SX } from 'shared/lib/sidebar/SidebarContainer';
import SidebarMenuItems from 'shared/lib/sidebar/SidebarMenuItems';
import SidebarTitle from 'shared/lib/sidebar/SidebarTitle';
import { selectSidebarCollapsed } from 'shared/state/slices/sidebarSlice';

function PartnerSidebar() {
  const collapsed = useSelector(selectSidebarCollapsed);
  const partnerPermissionsEnabled = useFeatureFlag('partner_permissions');

  const items = [];
  if (partnerPermissionsEnabled) {
    items.push({
      title: 'Users & Permissions',
      link: routes.getPartnerPermissions(),
      icon: <GroupsOutlinedIcon sx={ICON_SX} />,
    });
  }

  return (
    <SidebarContainer>
      <SidebarTitle
        collapsed={collapsed}
        testId="settings-section-name"
        title=""
      />
      <SidebarMenuItems collapsed={collapsed} items={items} />
    </SidebarContainer>
  );
}

export default PartnerSidebar;
