import { useMemo } from 'react';

import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import { useSelector } from 'react-redux';

import TabularData from 'shared/components/tabular-data/TabularData';
import TabularDataCellCurrency from 'shared/components/tabular-data/TabularDataCellCurrency';
import TabularDataCellCurrencyPercent from 'shared/components/tabular-data/TabularDataCellCurrencyPercent';
import TabularDataCellDonut from 'shared/components/tabular-data/TabularDataCellDonut';
import TabularDataCellIcon from 'shared/components/tabular-data/TabularDataCellIcon';
import TabularDataHeaderCell from 'shared/components/tabular-data/TabularDataHeaderCell';
import TabularDataHeaderRow from 'shared/components/tabular-data/TabularDataHeaderRow';
import TabularDataRow from 'shared/components/tabular-data/TabularDataRow';

import useFinancialForecastSummaryRows from 'forecasting/pages/forecasting/hooks/useFinancialForecastSummaryRows';
import { selectTrial } from 'shared/state/slices/trialSlice';

import { getColor, getPercent, getSum } from './utils';

function FinancialTabularData() {
  const { loading, rows: summaryRows } = useFinancialForecastSummaryRows();
  const trialCurrency = useSelector(selectTrial).currency;

  const reconciledExpense = useMemo(
    () => getSum(summaryRows, 'reconciled_expense'),
    [summaryRows],
  );
  const contractedTotal = useMemo(
    () => getSum(summaryRows, 'default_contract_value'),
    [summaryRows],
  );
  const forecastedTotal = useMemo(
    () => getSum(summaryRows, 'grand_total'),
    [summaryRows],
  );
  const overUnderTotal = useMemo(
    () => getSum(summaryRows, 'under_over'),
    [summaryRows],
  );
  const overUnderPercent = useMemo(
    () => getPercent(overUnderTotal, contractedTotal),
    [overUnderTotal, contractedTotal],
  );
  const recognizedPercent = useMemo(
    () => getPercent(reconciledExpense, contractedTotal),
    [reconciledExpense, contractedTotal],
  );
  const color = useMemo(() => getColor(overUnderTotal), [overUnderTotal]);

  return (
    <TabularData>
      <TabularDataHeaderRow>
        <TabularDataHeaderCell sx={{ textAlign: 'left' }} title="Financials" />
        <TabularDataHeaderCell title="LTD Reconciled" />
        <TabularDataHeaderCell title="% of Contracted" />
        <TabularDataHeaderCell title="Contracted Total" />
        <TabularDataHeaderCell title="Forecasted Total" />
        <TabularDataHeaderCell title="Over/Under Contract" />
      </TabularDataHeaderRow>
      <TabularDataRow>
        <TabularDataCellIcon
          icon={<MonetizationOnOutlinedIcon sx={{ fontSize: 16 }} />}
          title="Budget"
        />
        <TabularDataCellCurrency
          currency={trialCurrency}
          loading={loading}
          value={reconciledExpense}
        />
        <TabularDataCellDonut
          loading={loading}
          percentage={recognizedPercent}
        />
        <TabularDataCellCurrency
          currency={trialCurrency}
          loading={loading}
          value={contractedTotal}
        />
        <TabularDataCellCurrency
          color={color}
          currency={trialCurrency}
          loading={loading}
          value={forecastedTotal}
        />
        <TabularDataCellCurrencyPercent
          color={color}
          currency={trialCurrency}
          loading={loading}
          percentage={overUnderPercent}
          value={overUnderTotal}
        />
      </TabularDataRow>
    </TabularData>
  );
}

export default FinancialTabularData;
