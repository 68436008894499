import { useMemo } from 'react';

import { useNavigate } from 'react-router-dom';

import type { CondorGridOptions } from 'shared/components/ag-grid/types';

import type { SharedContractExpenseSummaryRow } from 'shared/lib/types';

export default function useClinicalExpenseSummaryGridOptions(
  isOpenPeriod = true,
): CondorGridOptions<SharedContractExpenseSummaryRow> {
  const navigate = useNavigate();

  return useMemo(
    () =>
      ({
        rowSelection: {
          mode: 'singleRow',
          checkboxes: false,
          enableClickSelection: true,
        },
        rowStyle: { cursor: 'pointer' },
        groupTotalRow: 'bottom' as const,
        grandTotalRow: 'bottom',
        suppressAggFuncInHeader: true,
        // TODO: re-enable this once we correctly get contract container slugs
        //   ...(isOpenPeriod
        //     ? {        //         onSelectionChanged: (params: SelectionChangedEvent) => handleCroContractContainerRedirect(params, navigate),
        //       }
        //     : {        //         onSelectionChanged: 'handleCroContractContainerRedirect',
        //       }),
      }) as const,
    [isOpenPeriod, navigate],
  );
}
