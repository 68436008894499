import { useMemo } from 'react';

import type {
  NewValueParams,
  ValueGetterParams,
} from '@ag-grid-community/core';

import {
  getNativeCurrencyMoneyCellConfig,
  getSelectCellConfig,
} from 'shared/components/ag-grid-cells/config';
import type {
  CondorColDef,
  CondorColGroupDef,
} from 'shared/components/ag-grid/types';

import type { StatusChangeConfirmationModalData } from 'accruals/pages/clinical-expenses/cro/grids/CroVersionHistoryGrid';
import useFeatureFlag from 'shared/helpers/useFeatureFlag';
import useHasPermission from 'shared/lib/permissions/useHasPermission';
import type {
  ContractContainerResponse,
  VersionHistoryRow,
} from 'shared/lib/types';
import { ContractStatusOptions } from 'shared/lib/types';

import OccContractVersionMenu from '../components/OccContractVersionMenu';

export default function useOccVersionHistoryColDefs(
  isOpenPeriod: boolean,
  setShowStatusChangeConfirmationModal?: (value: boolean) => void,
  setConfirmationModalData?: (value: StatusChangeConfirmationModalData) => void,
  contractContainers?: ContractContainerResponse[],
): Array<CondorColDef | CondorColGroupDef> {
  const isContractStatusV2Enabled = useFeatureFlag('contract_status_v2');
  const isSoftDeleteEnabled = useFeatureFlag('soft_delete');
  const canEditTrialInfo = useHasPermission(['canEditTrialInfo']);
  const canDeleteTrialInfo = useHasPermission(['canDeleteTrialInfo']);

  return useMemo(() => {
    const onContractStatusChanged = (params: NewValueParams) => {
      setConfirmationModalData?.({
        statusTraceIdForPeriod: params.data.status_trace_id_for_period,
        contractVersionTraceId: params.data.trace_id,
        contractContainerTraceId: params.data.contract_container_trace_id,
        newStatus: params.newValue,
        currentStatus: params.oldValue,
        versionName: params.data.version,
        existingEffectiveDate: params.data.effective_date,
        existingAipEffectiveDate: params.data.aip_effective_date,
        existingVoidedDate: params.data.voided_date,
      });
      setShowStatusChangeConfirmationModal?.(true);
    };

    return [
      { field: 'contract_container_trace_id', hide: true, rowGroup: true },
      { field: 'trace_id', hide: true },
      { field: 'status_trace_id_for_period', hide: true },
      { field: 'effective_date', hide: true },
      { field: 'aip_effective_date', hide: true },
      { field: 'voided_date', hide: true },
      {
        field: 'version',
        headerName: 'Contract Version',
        flex: 1,
        onCellClicked: 'handleOccContractVersionRedirect',
      },
      { field: 'po_number', headerName: 'PO #', flex: 1 },
      {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        cellRenderer: 'AgGridContractStatusRenderer',
        ...(isOpenPeriod
          ? {
              ...getSelectCellConfig(),
              cellRenderer: 'AgGridContractStatusRenderer',
              cellEditorParams: (_params: ValueGetterParams) => ({
                options: Object.entries(ContractStatusOptions)
                  .filter(([, label]) =>
                    isContractStatusV2Enabled
                      ? ![
                          ContractStatusOptions.AIP,
                          ContractStatusOptions.FUTURE,
                          ContractStatusOptions.SUPERSEDED,
                        ].includes(label)
                      : ![ContractStatusOptions.AIP].includes(label),
                  )
                  .map(([value, label]) => ({ value, label })),
              }),
              editable: canEditTrialInfo,
              onCellValueChanged: onContractStatusChanged,
            }
          : {}),
      },
      {
        field: 'net_contract_value',
        headerName: 'Contract Value',
        ...getNativeCurrencyMoneyCellConfig(),
        flex: 1,
      },
      {
        field: 'change_from_previous',
        headerName: 'Change from previous',
        ...getNativeCurrencyMoneyCellConfig(),
        flex: 1,
      },
      { field: 'execution_date', headerName: 'Date executed', flex: 1 },
      { field: 'last_used_date', headerName: 'Date last used', flex: 1 },
      ...(isOpenPeriod && canDeleteTrialInfo && isSoftDeleteEnabled
        ? [
            {
              sortable: false,
              resizable: false,
              suppressHeaderMenuButton: true,
              width: 50,
              cellClass: 'ag-cell-button',
              cellRenderer: ({ data }: { data: VersionHistoryRow }) =>
                OccContractVersionMenu({
                  data,
                  contractContainers,
                }),
            },
          ]
        : []),
    ];
  }, [
    isOpenPeriod,
    setConfirmationModalData,
    setShowStatusChangeConfirmationModal,
    canEditTrialInfo,
    canDeleteTrialInfo,
    isSoftDeleteEnabled,
    isContractStatusV2Enabled,
    contractContainers,
  ]);
}
