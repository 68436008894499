import { useCallback, useMemo } from 'react';

import Box from '@mui/system/Box';
import AxisBottom from '@visx/axis/lib/axis/AxisBottom';
import GridColumns from '@visx/grid/lib/grids/GridColumns';
import Group from '@visx/group/lib/Group';
import linear from '@visx/scale/lib/scales/linear';
import ordinal from '@visx/scale/lib/scales/ordinal';
import type { AnyD3Scale } from '@visx/scale/lib/types/Scale';
import { useSelector } from 'react-redux';

import { selectTrial } from 'shared/state/slices/trialSlice';
import { filterUndefined, parseNullableInt } from 'utils';

import {
  assumeTransparent,
  getPrefixedFormattedValue,
} from '../../../../shared/lib/graphing/graphUtils';
import AnimatedBar from '../../../../shared/lib/graphing/shared/AnimatedBar';
import ForecastAnimatedLine from '../../../../shared/lib/graphing/shared/ForecastAnimatedLine';
import GraphLegend from '../../../../shared/lib/graphing/shared/GraphLegend';
import { COST_FORECAST_CONFIG } from './config';
import type { CostForecastConfig, CostForecastData } from './types';

type Props = {
  data: CostForecastData;
  graphOptions?: CostForecastConfig;
  height: number;
  width: number;
};

const VERTICAL_OFFSET = 0;
const BAR_HEIGHT = 8;
const CONTRACTED_BAR_HEIGHT = 16;

function CostForecast(props: Props) {
  const { width, height, data, graphOptions = COST_FORECAST_CONFIG } = props;

  const trialCurrency = useSelector(selectTrial).currency;

  const isPrepaid = useMemo(
    () => data.invoiced.value > data.ltdExpensed.value,
    [data],
  );
  const isForecastGreater = useMemo(
    () => data.forecasted.value > data.contracted.value,
    [data],
  );

  const steps = useMemo(() => {
    const accruedStart = isPrepaid
      ? data.ltdExpensed.value
      : data.invoiced.value;
    const accruedEnd = isPrepaid ? data.invoiced.value : data.ltdExpensed.value;

    return {
      invoiced: {
        start: 0,
        end: accruedStart,
      },
      accrued: {
        start: accruedStart,
        end: accruedEnd,
      },
      contracted: {
        start: accruedEnd,
        end: data.contracted.value,
      },
      forecasted: {
        end: data.forecasted.value,
      },
    };
  }, [data, isPrepaid]);

  const { margin } = graphOptions;

  const left = parseNullableInt(margin?.left);
  const right = parseNullableInt(margin?.right);
  const top = parseNullableInt(margin?.top);
  const bottom = parseNullableInt(margin?.bottom);

  const innerWidth = width - left - right;
  const innerHeight = height - top - bottom;

  const xDomain = useMemo(() => {
    const values = [
      steps.contracted.start,
      steps.contracted.end,
      steps.invoiced.start,
      steps.invoiced.end,
      steps.accrued.start,
      steps.accrued.end,
      steps.forecasted.end,
    ];
    const max = Math.max(...values);
    return [0, max];
  }, [steps]);

  const xScale = linear({
    domain: xDomain,
    nice: true,
    range: [0, innerWidth],
  });

  const legendShapes = useCallback(
    (datum: ReturnType<AnyD3Scale>) => {
      if ([graphOptions.forecastedText].includes(datum.text)) {
        return { type: 'line' as const };
      }
      return {
        type: 'rect' as const,
        stroke:
          datum.text === graphOptions.contractedText
            ? graphOptions.contractedColorOuter
            : undefined,
      };
    },
    [
      graphOptions.contractedColorOuter,
      graphOptions.contractedText,
      graphOptions.forecastedText,
    ],
  );

  const additionalText = useCallback(
    ({ datum }: ReturnType<AnyD3Scale>) => {
      switch (datum) {
        case graphOptions.invoicedText:
          return getPrefixedFormattedValue(data.invoiced.value, trialCurrency);
        case graphOptions.accruedText:
        case graphOptions.prepaidText:
          return getPrefixedFormattedValue(
            Math.abs(data.ltdExpensed.value - data.invoiced.value),
            trialCurrency,
          );
        case graphOptions.forecastedText:
          return getPrefixedFormattedValue(
            data.forecasted.value - data.contracted.value,
            trialCurrency,
          );
        case graphOptions.contractedText:
          return getPrefixedFormattedValue(
            data.contracted.value,
            trialCurrency,
          );
        default:
          return '';
      }
    },
    [
      data,
      graphOptions.accruedText,
      graphOptions.contractedText,
      graphOptions.forecastedText,
      graphOptions.invoicedText,
      graphOptions.prepaidText,
    ],
  );

  if (width < 200) {
    // 'Width of Timeline Chart is too small';
    return null;
  }

  const colorRange = filterUndefined<string>([
    graphOptions.invoicedColor,
    isPrepaid ? 'url(#hatch)' : graphOptions.accruedColor,
    graphOptions.contractedColor,
    isForecastGreater
      ? graphOptions.overContractColor
      : graphOptions.underContractColor,
  ]);

  const barsColorScale = ordinal({
    domain: ['invoiced', 'accrued', 'contracted', 'forecasted'],
    range: colorRange,
  });

  const legendColorScale = ordinal<string, string>({
    domain: filterUndefined<string>([
      graphOptions.invoicedText,
      isPrepaid ? graphOptions.prepaidText : graphOptions.accruedText,
      graphOptions.contractedText,
      graphOptions.forecastedText,
    ]),
    range: colorRange,
  });

  const xScaledInvoicedStart = xScale(steps.invoiced.start);
  const xScaledInvoicedEnd = xScale(steps.invoiced.end);
  const xScaledAccruedStart = xScale(steps.accrued.start);
  const xScaledAccruedEnd = xScale(steps.accrued.end);
  const xScaledContractedEnd = xScale(steps.contracted.end);
  const xScaledForecastedX = xScale(steps.forecasted.end);

  // center in the "middle" of the chart (there is only one bar "visually", so we can just compute it, instead of letting it scale)
  const barY = innerHeight / 2 - BAR_HEIGHT / 2;
  const contractedY = innerHeight / 2 - CONTRACTED_BAR_HEIGHT / 2;

  return (
    <Box sx={{ display: 'grid' }}>
      <GraphLegend
        additionalText={additionalText}
        colorScale={legendColorScale}
        shapes={legendShapes}
      />
      <svg height={height} width={width}>
        <Group left={left} top={top}>
          {isPrepaid && (
            <defs>
              <pattern
                height="6"
                id="hatch"
                patternTransform="rotate(45)"
                patternUnits="userSpaceOnUse"
                width="6"
              >
                <line
                  stroke={graphOptions.invoicedColor}
                  strokeWidth={4}
                  x1="4"
                  x2="4"
                  y1="0"
                  y2="10"
                />
                <line
                  stroke={graphOptions.accruedColor}
                  strokeWidth={6}
                  x1="0"
                  x2="0"
                  y1="0"
                  y2="10"
                />
              </pattern>
            </defs>
          )}
          <GridColumns
            height={innerHeight}
            scale={xScale}
            stroke={graphOptions.columnColor}
          />
          <AnimatedBar
            fill={barsColorScale('invoiced')}
            height={BAR_HEIGHT}
            rx={2}
            ry={2}
            verticalOffset={VERTICAL_OFFSET}
            width={xScaledInvoicedEnd - xScaledInvoicedStart}
            x={xScaledInvoicedStart}
            y={barY}
          />
          <AnimatedBar
            fill={barsColorScale('accrued')}
            height={BAR_HEIGHT}
            rx={2}
            ry={2}
            verticalOffset={VERTICAL_OFFSET}
            width={xScaledAccruedEnd - xScaledAccruedStart}
            x={xScaledAccruedStart}
            y={barY}
          />
          <AnimatedBar
            fill={barsColorScale('contracted')}
            height={CONTRACTED_BAR_HEIGHT}
            rx={2}
            ry={2}
            stroke={graphOptions.contractedColorOuter}
            strokeWidth="0.5px"
            verticalOffset={VERTICAL_OFFSET}
            width={xScaledContractedEnd}
            x={0}
            y={contractedY}
          />
          <ForecastAnimatedLine
            isGreater={isForecastGreater}
            verticalOffset={VERTICAL_OFFSET}
            x={xScaledForecastedX}
            y={barY + BAR_HEIGHT / 2}
            overContractColor={assumeTransparent(
              graphOptions.overContractColor,
            )}
            underContractColor={assumeTransparent(
              graphOptions.underContractColor,
            )}
          />
          <AxisBottom
            scale={xScale}
            tickFormat={(value) => getPrefixedFormattedValue(value, '')}
            top={innerHeight}
            tickLabelProps={() => ({
              fill: graphOptions.textColor,
              fontSize: 13,
              fontWeight: 600,
              textAnchor: 'middle',
              verticalAnchor: 'middle',
            })}
            hideAxisLine
            hideTicks
          />
        </Group>
      </svg>
    </Box>
  );
}

export default CostForecast;
