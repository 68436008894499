import type { PartnerResponse } from 'shared/lib/types';

import constructApi from './constructApi';

export const PARTNERS_TAG = 'PARTNERS';

const apiEndpointFactory =
  constructApi<PartnerResponse>('companygroups').withTags(PARTNERS_TAG);
const api = apiEndpointFactory.inject(() => ({
  getPartners: apiEndpointFactory.getAll(),
}));

export const { useGetPartnersQuery } = api;
