import type { ReactElement } from 'react';
import { useMemo, useState } from 'react';

import CreateIcon from '@mui/icons-material/CreateOutlined';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ParentSize from '@visx/responsive/lib/components/ParentSize';

import Button from 'shared/ui/button/Button';
import DashboardCard from 'shared/ui/dashboard-card/DashboardCard';

import TrialSpendGraph from 'accruals/components/graphing/trial-spend-graph/TrialSpendGraph';

import HistoricValuesDialog from 'accruals/pages/trial/dialogs/HistoricValuesDialog';
import PageContentLoadingContainer from 'shared/containers/page-content-loading-container/PageContentLoadingContainer';
import withPeriodSpecificGenericWrapper from 'shared/lib/periods/withPeriodSpecificGenericWrapper';
import useHasPermission from 'shared/lib/permissions/useHasPermission';

import withAccrualPageRequiredData from 'accruals/api/hocs/withAccrualPageRequiredData';

import TrialActivityCard from './components/TrialActivityCard';
import TrialExpenseSummaryGrid from './grids/TrialExpenseSummaryGrid';

function HistoricalValuesButton({
  onClick,
}: { onClick: () => void }): ReactElement {
  return (
    <Button
      startIcon={<CreateIcon />}
      testId="btn_add_historical_expenses"
      variant="outlined"
      onClick={onClick}
    >
      Add Historical Expenses
    </Button>
  );
}
const PeriodAwareHistoricalValuesButton = withPeriodSpecificGenericWrapper(
  HistoricalValuesButton,
);

function TrialDashboardPage(): ReactElement {
  const userCanEditHistoricValuesOrPeriodClosedValues = useHasPermission(
    ['canEditHistoricValues', 'canEditPeriodClosedValues'],
    'OR',
  );

  const [editHistoricOpen, setEditHistoricOpen] = useState(false);
  const gridSx = useMemo(() => ({ height: '100%' }), []);

  return (
    <PageContentLoadingContainer>
      <Grid spacing={3} container>
        <Grid xs={12} item>
          <DashboardCard title="Trial Spend">
            <Box sx={useMemo(() => ({ height: 600, overflowX: 'auto' }), [])}>
              <ParentSize>
                {(parent) => (
                  <TrialSpendGraph
                    height={parent.height}
                    width={parent.width}
                  />
                )}
              </ParentSize>
            </Box>
          </DashboardCard>
        </Grid>
        <TrialActivityCard />
        <Grid xs={12} item>
          <DashboardCard
            title="Trial Expense Summary"
            titleAction={
              userCanEditHistoricValuesOrPeriodClosedValues ? (
                <PeriodAwareHistoricalValuesButton
                  onClick={() => setEditHistoricOpen(true)}
                />
              ) : undefined
            }
          >
            <Box sx={{ height: 600 }}>
              <TrialExpenseSummaryGrid
                overlayNoRowsTemplate="Trial summary will appear when accruals for the current and prior periods are available."
                sx={gridSx}
              />
            </Box>
          </DashboardCard>
        </Grid>
      </Grid>
      {editHistoricOpen && (
        <HistoricValuesDialog onClose={() => setEditHistoricOpen(false)} />
      )}
    </PageContentLoadingContainer>
  );
}

export default withAccrualPageRequiredData(TrialDashboardPage);
