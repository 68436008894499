import type { ReactNode } from 'react';

import { useParams } from 'react-router-dom';

import NavigationTabs from 'shared/components/tabs/NavigationTabs';

import * as routes from 'routes';
import FlexGridContainer from 'shared/containers/flex-grid-container/FlexGridContainer';
import PageContentLoadingContainer from 'shared/containers/page-content-loading-container/PageContentLoadingContainer';
import useFeatureFlag from 'shared/helpers/useFeatureFlag';

type Props = {
  basePath: string;
  current: ReactNode;
  historic: ReactNode;
};

function InvoiceContainerPage({ basePath, current, historic }: Props) {
  const { activeTab } = useParams();
  const historicActive = useFeatureFlag('historic_invoices');

  if (!historicActive) {
    return (
      <PageContentLoadingContainer>
        <FlexGridContainer>{current}</FlexGridContainer>
      </PageContentLoadingContainer>
    );
  }

  const TABS = [
    { key: 'current', label: 'Current' },
    { key: 'historic', label: 'Historic' },
  ];

  const generateUrl = (tabKey: string) =>
    routes.getInvoicesTab(basePath, tabKey);

  return (
    <PageContentLoadingContainer
      tabs={
        <NavigationTabs
          activeTab={activeTab}
          generateUrl={generateUrl}
          tabs={TABS}
        />
      }
    >
      <FlexGridContainer>
        {activeTab === 'current' && current}
        {activeTab === 'historic' && historic}
      </FlexGridContainer>
    </PageContentLoadingContainer>
  );
}

export default InvoiceContainerPage;
