import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import type { Theme } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles/';
import { alpha } from '@mui/system/colorManipulator';

import { darkPalette, lightPalette } from 'palettes';

const FONT_FAMILY = 'Figtree, Arial, Helvetica, sans-serif';

declare module '@mui/material/Button' {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions -- Interface is necessary for Declaration Merging
  interface ButtonPropsVariantOverrides {
    title: true;
  }
}

declare module '@mui/material/IconButton' {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions -- Interface is necessary for Declaration Merging
  interface IconButtonPropsVariantOverrides {
    primary: true;
    secondary: true;
    tertiary: true;
  }
}

declare module '@mui/material/Tabs' {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions -- Interface is necessary for Declaration Merging
  interface TabsPropsVariantOverrides {
    small: true;
  }
}

function theme(mode: 'dark' | 'light'): Theme {
  const palette = mode === 'dark' ? darkPalette : lightPalette;
  return createTheme({
    palette,
    typography: {
      fontFamily: FONT_FAMILY,
      fontSize: 14,
      htmlFontSize: 14,
      h1: { fontSize: '4.571rem', lineHeight: '125%', letterSpacing: 0 },
      h2: { fontSize: '3.429rem', lineHeight: '125%', letterSpacing: 0 },
      h3: {
        fontSize: '1.714rem',
        lineHeight: '125%',
        letterSpacing: 0,
        fontWeight: 500,
      },
      h4: {
        fontSize: '1.429rem',
        lineHeight: '150%',
        letterSpacing: 0,
        fontWeight: 500,
      },
      h5: { fontSize: '1.571rem', lineHeight: '150%', letterSpacing: 0 },
      h6: { fontSize: '1.286rem', lineHeight: '150%', letterSpacing: 0 },
      subtitle1: {
        fontSize: '1.286rem',
        lineHeight: '150%',
        letterSpacing: 0,
        fontWeight: 700,
      },
      subtitle2: {
        fontSize: '.928rem',
        lineHeight: '18px',
        letterSpacing: 0.15,
        fontWeight: 700,
      },
      body1: { fontSize: '.928rem', lineHeight: '18px', letterSpacing: 0.15 },
      body2: {
        fontSize: '.857rem',
        lineHeight: '18px',
        letterSpacing: 0.15,
        fontWeight: 700,
      },
      caption: { fontSize: '.857rem', lineHeight: '18px', letterSpacing: 0.15 },
      overline: {
        fontSize: '.786rem',
        lineHeight: '16px',
        letterSpacing: 1.25,
      },
      button: {
        fontSize: '.857rem',
        lineHeight: '16px',
        letterSpacing: 1.25,
        fontWeight: 600,
      },
    },
    components: {
      MuiAlert: {
        styleOverrides: {
          root: {
            minWidth: '320px',
            maxWidth: '420px',
            color: palette.common.black,
            boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.2)',
            border: '1px solid',
          },
          message: { display: 'flex', flexDirection: 'column', gap: '8px' },
          standard: {
            backgroundColor: palette.grey[100],
            color: palette.grey[900],
            borderColor: palette.grey[300],
          },
          standardError: {
            backgroundColor: palette.error.light,
            borderColor: palette.error.main,
          },
          standardSuccess: {
            backgroundColor: palette.success.light,
            borderColor: palette.success.main,
          },
          standardWarning: {
            backgroundColor: palette.warning.light,
            borderColor: palette.warning.main,
          },
          action: { paddingTop: '7px' },
        },
      },
      MuiAlertTitle: {
        styleOverrides: {
          root: { marginTop: '-1px', marginBottom: '0', fontWeight: 700 },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            boxShadow:
              mode === 'light'
                ? 'rgba(0, 0, 0, 0.08) 0px 2px 4px -1px, rgba(0, 0, 0, 0.05) 0px 4px 5px 0px, rgba(0, 0, 0, 0) 0px 1px 10px 0px'
                : 'rgb(0, 0, 0) 0px 2px 4px -1px, rgb(0, 0, 0) 0px 4px 5px 0px, rgb(0, 0, 0) 0px 1px 10px 0px',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontWeight: '600',
            '&:focus': { boxShadow: `inset 0 0 0 2px ${palette.primary.dark}` },
          },
          sizeSmall: {
            padding: '7px 12px',
            fontSize: '.786rem',
            fontWeight: '600',
            lineHeight: '18px',
            letterSpacing: '1.25px',
          },
          sizeMedium: {
            padding: '8px 16px',
            fontWeight: '600',
            lineHeight: '24px',
            letterSpacing: '1.25px',
          },
          sizeLarge: {
            padding: '16px 24px',
            fontWeight: '600',
            lineHeight: '24px',
            letterSpacing: '2px',
          },
          contained: {
            backgroundColor: palette.primary.main,
            border: 'none',
            color: palette.common.white,
            boxShadow: 'none',
            '&:hover': {
              backgroundColor: palette.primary.dark,
              boxShadow: 'none',
            },
            '&:active, &.MuiButton-active': {
              backgroundColor: palette.primary.dark,
              boxShadow: 'none',
            },
            '&:disabled': {
              backgroundColor: alpha(palette.primary.main, 0.4),
              border: 'none',
              color: palette.common.white,
              boxShadow: 'none',
            },
          },
          outlined: {
            border: 'none',
            boxShadow: `inset 0 0 0 1px ${palette.primary.main}`,
            '&:hover': {
              border: 'none',
              boxShadow: `inset 0 0 0 1px ${palette.primary.main}`,
            },
            '&:active, &.MuiButton-active': {
              border: 'none',
              boxShadow: `inset 0 0 0 1px ${palette.primary.main}`,
              backgroundColor: palette.primary.light,
            },
            '&:disabled': {
              border: 'none',
              boxShadow: `inset 0 0 0 1px ${palette.primary.main}`,
              color: palette.primary.main,
              opacity: '0.4',
            },
          },
          text: {
            '&.MuiButton-sizeSmall': { padding: '7px 0' },
            '&.MuiButton-sizeMedium': { padding: '8px 0' },
            '&.MuiButton-sizeLarge': { padding: '16px 0' },
            color: palette.grey[900],
            '&:hover': {
              color: palette.common.black,
              background: 'transparent',
            },
            '&:active, &.MuiButton-active': {
              background: 'transparent',
            },
            '&:disabled': { color: palette.grey[900], opacity: '0.4' },
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            '&.Mui-checked, &.MuiCheckbox-indeterminate': {
              color: palette.primary.main,
            },
            '&.Mui-disabled': { color: palette.grey[500] },
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            display: 'inline-flex',
            verticalAlign: 'inherit',
            color: palette.grey[700],
            background: palette.grey[100],
            mixBlendMode: mode === 'light' ? 'multiply' : 'normal',
            '&:hover': { boxShadow: `inset 0 0 0 1px ${palette.grey[700]}` },
            '&:focus': { boxShadow: `0 0 0 2px ${palette.grey[700]}` },
          },
          sizeSmall: { height: '24px', fontSize: '12px', lineHeight: '18px' },
          sizeMedium: { height: '32px', fontSize: '14px', lineHeight: '22px' },
          iconSmall: {
            width: '12px',
            height: '12px',
            fontSize: '12px',
            marginLeft: '8px',
          },
          iconMedium: {
            width: '18px',
            height: '18px',
            fontSize: '18px',
            marginLeft: '12px',
          },
          colorPrimary: {
            color: palette.primary.main,
            '&:hover': { boxShadow: `inset 0 0 0 1px ${palette.primary.main}` },
            '&:focus': { boxShadow: `0 0 0 2px ${palette.primary.main}` },
          },
          colorSuccess: {
            color: palette.success.dark,
            background: palette.success.light,
            '&:hover': { boxShadow: `inset 0 0 0 1px ${palette.success.dark}` },
            '&:focus': { boxShadow: `0 0 0 2px ${palette.success.dark}` },
          },
          colorWarning: {
            color: palette.warning.dark,
            background: palette.warning.light,
            '&:hover': { boxShadow: `inset 0 0 0 1px ${palette.warning.dark}` },
            '&:focus': { boxShadow: `0 0 0 2px ${palette.warning.dark}` },
          },
          colorError: {
            color: palette.error.dark,
            background: palette.error.light,
            '&:hover': { boxShadow: `inset 0 0 0 1px ${palette.error.dark}` },
            '&:focus': { boxShadow: `0 0 0 2px ${palette.error.dark}` },
          },
          deleteIcon: {
            borderRadius: '50%',
            transition: '.3s',
            color: palette.grey[900],
            '&:hover': { color: 'inherit', background: 'rgba(0,0,0, 0.05)' },
          },
          deleteIconSmall: {
            width: '12px',
            height: '12px',
            fontSize: '12px',
            marginLeft: '-6px',
            marginRight: '8px',
          },
          deleteIconMedium: {
            width: '18px',
            height: '18px',
            fontSize: '18px',
            marginLeft: '-8px',
            marginRight: '12px',
          },
          deleteIconColorPrimary: { color: palette.primary.main },
          // @ts-expect-error MUI missing the class in its config
          deleteIconColorSuccess: { color: palette.success.dark },
          deleteIconColorWarning: { color: palette.warning.dark },
          deleteIconColorError: { color: palette.error.dark },
        },
      },
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            transition: 'all 0.3s linear', // Smooth transition between light and dark mode
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            '& .MuiBackdrop-root': { backgroundColor: 'rgba(0, 0, 0, 0.5)' },
          },
          paper: { borderRadius: '4px' },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            padding: '24px 32px',
            borderTop: `1px solid ${palette.grey[300]}`,
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: { root: { padding: '24px 32px', paddingTop: 0 } },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            margin: 0,
            padding: '24px 32px',
            color: palette.grey[900],
          },
        },
      },
      MuiFab: {
        styleOverrides: {
          root: {
            backgroundColor: palette.common.white,
            '&:hover': { backgroundColor: palette.primary.light },
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: { alignItems: 'flex-start' },
          label: {
            marginTop: '10px',
            fontSize: '14px',
            lineHeight: '22px',
            letterSpacing: '0.15px',
            color: palette.common.black,
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            margin: 0,
            marginTop: '4px',
            fontSize: '12px',
            lineHeight: '18px',
            letterSpacing: '0.15px',
            color: palette.grey[700],
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: { fontSize: '14px', lineHeight: '22px' },
          asterisk: {
            color: palette.error.main,
            '&$error': { color: palette.error.main },
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            '&:focus': { boxShadow: `inset 0 0 0 2px ${palette.primary.dark}` },
          },
          sizeMedium: { padding: '8px' },
          sizeLarge: { padding: '16px' },
        },
        variants: [
          {
            // @ts-expect-error MUI bug: https://github.com/mui/material-ui/issues/33510
            props: { variant: 'primary' },
            style: {
              backgroundColor: palette.primary.main,
              color: palette.common.white,
              '&:hover, &:active': { backgroundColor: palette.primary.dark },
              '&:disabled': {
                backgroundColor: palette.primary.main,
                color: palette.common.white,
                opacity: '0.4',
              },
            },
          },
          {
            // @ts-expect-error MUI bug: https://github.com/mui/material-ui/issues/33510
            props: { variant: 'secondary' },
            style: {
              color: palette.primary.main,
              '&:hover': {
                color: palette.primary.dark,
              },
              '&:active': {
                backgroundColor: palette.primary.light,
              },
              '&:disabled': {
                color: palette.primary.main,
                opacity: '0.4',
              },
            },
          },
          {
            // @ts-expect-error MUI bug: https://github.com/mui/material-ui/issues/33510
            props: { variant: 'tertiary' },
            style: {
              backgroundColor: 'transparent',
              color: palette.grey[900],
              '&:hover': {
                backgroundColor: 'transparent',
                color: palette.common.black,
              },
              '&:active': {
                backgroundColor: palette.primary.light,
              },
              '&:disabled': {
                backgroundColor: 'transparent',
                color: palette.grey[900],
                opacity: '0.4',
              },
            },
          },
        ],
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            [`&.${outlinedInputClasses.disabled}`]: {
              boxShadow: 'none',
              backgroundColor: palette.grey[100],
              color: palette.grey[500],
              [`.${outlinedInputClasses.notchedOutline}`]: { border: 'none' },
            },
          },
          input: { fontSize: '14px', lineHeight: '22px', padding: '16px' },
          inputSizeSmall: { padding: '8px 16px' },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: { color: palette.primary.main },
          underlineAlways: {
            textDecoration: `solid underline ${palette.primary.main}`,
            '&:hover': {
              textDecoration: `solid underline ${palette.primary.main}`,
            },
          },
          underlineHover: {
            '&:hover': {
              textDecoration: `solid underline ${palette.primary.main}`,
            },
          },
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            transition: '.3s',
            '&&& .MuiTouchRipple-child': {
              backgroundColor: palette.primary.light,
            },
          },
        },
      },
      MuiModal: {
        styleOverrides: {
          root: { '& .MuiBackdrop-root': { backgroundColor: 'tranparent' } },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            '&:hover $notchedOutline': { borderColor: palette.common.black },
            '&$focused $notchedOutline': {
              borderColor: palette.primary.main,
              borderWidth: '2px',
            },
            [`&.${outlinedInputClasses.error}`]: {
              [`.${outlinedInputClasses.notchedOutline}`]: {
                borderColor: palette.error.main,
              },
            },
          },
          notchedOutline: { borderColor: palette.grey[300] },
        },
      },
      MuiPaper: {
        styleOverrides: {
          elevation1: {
            boxShadow:
              mode === 'light'
                ? 'rgba(0, 0, 0, 0.08) 0px 2px 6px 1px, rgba(0, 0, 0, 0.08) 0px 1px 1px 0px, rgba(0, 0, 0, 0.08) 0px 1px 0px -1px'
                : 'rgb(0, 0, 0) 0px 2px 6px 1px, rgb(0, 0, 0) 0px 1px 1px 0px, rgb(0, 0, 0) 0px 1px 0px -1px',
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            '&.Mui-checked': { color: palette.primary.main },
            '&.Mui-disabled': { color: palette.grey[500] },
          },
        },
      },
      MuiSnackbarContent: {
        styleOverrides: { root: { backgroundColor: palette.common.black } },
      },
      MuiStep: {
        styleOverrides: {
          root: {
            paddingLeft: '16px',
            paddingRight: '8px',
            cursor: 'pointer',
            '&:first-of-type': { paddingLeft: 0 },
            '&:last-child': { paddingRight: 0 },
          },
        },
      },
      MuiStepConnector: {
        styleOverrides: { root: { flex: '0 0 auto', width: '64px' } },
      },
      MuiStepLabel: {
        styleOverrides: {
          label: {
            fontWeight: 400,
            color: palette.grey[700],
            '&.Mui-completed': { fontWeight: 400, color: palette.grey[700] },
            '&.Mui-active': { fontWeight: 700, color: palette.primary.main },
          },
        },
      },
      MuiSwitch: {
        styleOverrides: {
          root: { width: '54px', height: '42px' },
          track: {
            width: '30px',
            height: '18px',
            backgroundColor: 'transparent',
            boxShadow: `inset 0 0 0 2px ${palette.grey[700]}`,
            borderRadius: '50px',
            opacity: '1 !important',
            transition: '.3s',
          },
          thumb: {
            width: '12px',
            height: '12px',
            backgroundColor: palette.grey[700],
            boxShadow: 'none',
            transition: '.3s',
          },
          switchBase: {
            top: '15px',
            left: '15px',
            padding: '0',
            '&.Mui-checked': {
              transform: 'translateX(12px)',
              '& + .MuiSwitch-track': {
                backgroundColor: palette.primary.main,
                boxShadow: 'none',
              },
              '.MuiSwitch-thumb': { backgroundColor: palette.common.white },
            },
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            '.MuiTab-root': {
              minWidth: 0,
              padding: '0 24px',
              fontSize: '12px',
              lineHeight: '14px',
              fontWeight: 600,
              letterSpacing: '1.25px',
              color: palette.grey[900],
              '&:hover': { color: palette.common.black },
              '&:focus': {
                boxShadow: `inset 0 0 0 2px ${palette.primary.dark}`,
              },
              '&.Mui-selected': { color: palette.primary.main },
              '&.Mui-disabled.Mui-disabled.Mui-disabled': {
                color: palette.grey[900],
                opacity: 0.4,
              },
            },
          },
          vertical: {
            '.MuiTab-root': {
              maxWidth: '300px',
              minHeight: '48px',
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: '0 16px',
              borderBottom: `1px solid ${palette.grey[300]}`,
              fontSize: '14px',
              fontWeight: 400,
              letterSpacing: '0.15px',
              textAlign: 'left',
              textTransform: 'none',
              transition: '.3s',
              '&:hover': { backgroundColor: palette.grey[100] },
              '&.Mui-selected': {
                borderBottomColor: 'transparent',
              },
            },
            '.MuiTabs-indicator': { display: 'none' },
          },
        },
        variants: [
          {
            // @ts-expect-error MUI bug https://github.com/mui/material-ui/issues/33785
            props: { variant: 'small' },
            style: {
              minHeight: '32px',
              '.MuiTabs-flexContainer': { gap: '24px' },
              '.MuiTab-root': {
                minHeight: '32px',
                padding: '0 2px',
                fontSize: '12px',
                lineHeight: '18px',
                fontWeight: 400,
                letterSpacing: '0.15px',
                color: palette.grey[900],
                textTransform: 'none',
                '&.Mui-selected': { color: palette.primary.main },
              },
            },
          },
        ],
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            color: palette.primary.main,
            borderColor: palette.primary.main,
            transition: '.3s',
            '&:not(:first-of-type)': {
              borderLeftColor: `${palette.primary.main} !important`,
              borderTopColor: `${palette.primary.main} !important`,
            },
            '& > svg': { marginLeft: '-8px', marginRight: '-8px' },
            '&:hover': {
              color: palette.primary.main,
            },
            '&:disabled': {
              color: palette.primary.main,
              borderColor: palette.primary.main,
              opacity: '0.4',
            },
            '&.Mui-selected': {
              backgroundColor: palette.primary.main,
              color: palette.common.white,
              '&:hover': {
                backgroundColor: palette.primary.dark,
                color: palette.common.white,
              },
            },
          },
          sizeSmall: {
            padding: '7px 12px',
            fontSize: '.786rem',
            fontWeight: '600',
            lineHeight: '18px',
            letterSpacing: '1.25px',
          },
          sizeMedium: {
            padding: '8px 16px',
            fontSize: '.928rem',
            fontWeight: '600',
            lineHeight: '24px',
            letterSpacing: '1.25px',
          },
          sizeLarge: {
            padding: '16px 24px',
            fontSize: '1.285rem',
            fontWeight: '600',
            lineHeight: '24px',
            letterSpacing: '2px',
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor:
              mode === 'light'
                ? 'rgba(33, 33, 33, 90%)'
                : 'rgba(255 255 255 / 90%)',
          },
          arrow: {
            color:
              mode === 'light'
                ? 'rgba(33, 33, 33, 90%)'
                : 'rgba(255 255 255 / 90%)',
          },
        },
      },
    },
  });
}

export default theme;
