import { useState } from 'react';

import type { ICellRendererParams } from '@ag-grid-community/core';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { useSelector } from 'react-redux';

import AddEditGlAccountDialog from 'shared/components/gl-accounts/AddEditGlAccountDialog';
import DeleteGlAccountDialog from 'shared/components/gl-accounts/DeleteGlAccountDialog';
import KabobMenu from 'shared/components/kabob-menu/KabobMenu';

import useFeatureFlag from 'shared/helpers/useFeatureFlag';
import useHasPermission from 'shared/lib/permissions/useHasPermission';
import { selectCompany } from 'shared/state/slices/companySlice';

import { useGetContractsByGlAccountQuery } from 'shared/api/rtkq/contracts';
import { useDeleteGlAccountMutation } from 'shared/api/rtkq/glaccounts';

export default function GlAccountCellRenderer(props: ICellRendererParams) {
  const { data } = props;
  const { accountNumber, traceId } = data;

  const company = useSelector(selectCompany);
  const isSoftDeleteEnabled = useFeatureFlag('soft_delete');
  const canEditCompanyLevelInfo = useHasPermission(['canEditCompanyLevelInfo']);
  const canDeleteCompanyLevelInfo = useHasPermission([
    'canDeleteCompanyLevelInfo',
  ]);

  const { currentData: contracts } = useGetContractsByGlAccountQuery(
    traceId ?? skipToken,
  );
  const [deleteGlAccount] = useDeleteGlAccountMutation();

  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState(false);
  const [isEditGlAccountModalOpen, setIsEditGlAccountModalOpen] =
    useState(false);

  function onDelete() {
    if (traceId !== null) {
      void deleteGlAccount(traceId);
    }
  }

  return (
    <>
      <KabobMenu
        options={[
          ...(canEditCompanyLevelInfo
            ? [
                {
                  label: 'Edit',
                  onClick: () => setIsEditGlAccountModalOpen(true),
                },
              ]
            : []),
          ...(isSoftDeleteEnabled && canDeleteCompanyLevelInfo
            ? [
                {
                  label: 'Delete',
                  onClick: () => setIsDeleteConfirmationModalOpen(true),
                },
              ]
            : []),
        ]}
      />
      <DeleteGlAccountDialog
        accountNumber={accountNumber}
        contracts={contracts}
        isDeleteConfirmationModalOpen={isDeleteConfirmationModalOpen}
        onClose={() => setIsDeleteConfirmationModalOpen(false)}
        onDelete={onDelete}
      />
      {isEditGlAccountModalOpen && (
        <AddEditGlAccountDialog
          companyTraceId={company.trace_id}
          editAccountTraceId={traceId}
          onClose={() => setIsEditGlAccountModalOpen(false)}
        />
      )}
    </>
  );
}
