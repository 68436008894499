import { useMemo } from 'react';

import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import ChangeCircleOutlined from '@mui/icons-material/ChangeCircleOutlined';
import DescriptionIcon from '@mui/icons-material/DescriptionOutlined';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import * as routes from 'routes';
import { humanize } from 'shared/helpers/helpers';
import useFeatureFlag from 'shared/helpers/useFeatureFlag';
import SidebarContainer, {
  DIVIDER,
  ICON_SX,
} from 'shared/lib/sidebar/SidebarContainer';
import SidebarMenuItems from 'shared/lib/sidebar/SidebarMenuItems';
import type { SidebarSectionItem } from 'shared/lib/sidebar/SidebarSection';
import SidebarSkeletonContainer from 'shared/lib/sidebar/SidebarSkeletonContainer';
import SidebarTitle from 'shared/lib/sidebar/SidebarTitle';
import { selectCompany } from 'shared/state/slices/companySlice';
import { selectSidebarCollapsed } from 'shared/state/slices/sidebarSlice';
import { useChangeTrial } from 'shared/state/slices/trialSlice';

import { useGetTrialsByCompanyQuery } from 'shared/api/rtkq/trials';

const COMPANY_MENU_ITEMS_BASE = [
  {
    title: 'Accruals',
    icon: <CalculateOutlinedIcon sx={ICON_SX} />,
    subNav: [
      { title: 'PO Listing', link: routes.getPurchaseOrders() },
      { title: 'Invoice listing', link: routes.getInvoices() },
      { title: 'FX Rates', link: routes.getFxRates() },
      { title: 'G/L accounts', link: routes.getGlAccounts() },
    ],
  },
  {
    title: 'Audit Log',
    link: routes.getAuditLog(),
    icon: <ChangeCircleOutlined sx={ICON_SX} />,
  },
  {
    title: 'Company Files',
    link: routes.getCompanyFiles(),
    icon: <DescriptionIcon sx={ICON_SX} />,
  },
];

const SETTINGS_MENU_ITEM = {
  title: 'Settings',
  link: routes.getSettings(),
  icon: <SettingsOutlinedIcon sx={ICON_SX} />,
};

function CompanySidebar() {
  const collapsed = useSelector(selectSidebarCollapsed);

  const navigate = useNavigate();
  const company = useSelector(selectCompany);
  const changeTrial = useChangeTrial();
  const isCompanySettingsEnabled = useFeatureFlag('company_settings');

  const {
    currentData: trials,
    isUninitialized,
    isFetching,
    isLoading,
  } = useGetTrialsByCompanyQuery(company.trace_id);

  const trialMenuItems: SidebarSectionItem[] = useMemo(() => {
    if (!trials) {
      return [];
    }

    const trialsByProgram = groupBy(trials, 'program.trace_id');
    const allTrialPrograms = trials.map((trial) => trial.program);
    const programs = orderBy(uniqBy(allTrialPrograms, 'trace_id'), [
      (po) => po.name.toLowerCase(),
    ]);

    return programs.map((program) => {
      const programTrials = orderBy(trialsByProgram[program.trace_id], [
        (tr) => tr.study_id.toLowerCase(),
        (tr) => tr.indication.toLowerCase(),
      ]);

      return {
        title: program.name,
        icon: <QueryStatsIcon sx={{ fontSize: '1.2rem' }} />,
        subNav: programTrials.map((trial) => ({
          title: `${trial.study_id} • ${trial.indication}`,
          status: humanize(trial.status ?? ''),
          phase: trial.phase,
          onClick: () => {
            changeTrial(trial);
            navigate(routes.getTrialDashboard());
          },
        })),
      };
    });
  }, [changeTrial, navigate, trials]);

  const isTrialsLoading = isUninitialized || isFetching || isLoading;
  const items = isCompanySettingsEnabled
    ? [...COMPANY_MENU_ITEMS_BASE, SETTINGS_MENU_ITEM, DIVIDER]
    : [...COMPANY_MENU_ITEMS_BASE, DIVIDER];

  return (
    <SidebarContainer>
      <SidebarTitle
        collapsed={collapsed}
        testId="company-section-name"
        title="Company"
      />
      <SidebarMenuItems collapsed={collapsed} items={items} />
      <SidebarTitle
        collapsed={collapsed}
        testId="programs-and-trials-section-name"
        title=" Programs & Trials"
      />
      {isTrialsLoading ? (
        <SidebarSkeletonContainer collapsed={collapsed} numSkeletons={4} />
      ) : (
        <SidebarMenuItems collapsed={collapsed} items={trialMenuItems} />
      )}
    </SidebarContainer>
  );
}

export default CompanySidebar;
