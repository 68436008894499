import { useMemo } from 'react';

import type {
  CellClickedEvent,
  CellFocusedEvent,
  CellValueChangedEvent,
} from '@ag-grid-community/core';
import { useDispatch, useSelector } from 'react-redux';

import type { CondorGridOptions } from 'shared/components/ag-grid/types';

import { currencyToNumberFormatter, formatValue } from 'formatters';
import useHasPermission from 'shared/lib/permissions/useHasPermission';
import type { RegionResponse, SiteGridRow, TraceId } from 'shared/lib/types';
import { openEditSite } from 'shared/state/slices/siteSlice';
import { selectTrial } from 'shared/state/slices/trialSlice';

import {
  useDeleteSafeAdministrativeOrProcedureCostMutation,
  useUpsertAdministrativeOrProcedureCostMutation,
} from 'shared/api/rtkq/administrativeorprocedurecosts';
import { useGetRegionsByTrialQuery } from 'shared/api/rtkq/regions';
import {
  useCreateSiteContractMutation,
  useUpdateSiteContractMutation,
} from 'shared/api/rtkq/sitecontracts';
import { useUpdateSiteMutation } from 'shared/api/rtkq/sites';
import {
  useDeleteSafeVisitCostMutation,
  useUpsertVisitCostMutation,
} from 'shared/api/rtkq/visitcosts';

type AgGridContext = { trialRegions?: RegionResponse[] };

export default function useSiteCostMatrixGridOptions(
  isOpenPeriod: boolean,
  onAddNewSiteVersionSuccess?: () => void,
): CondorGridOptions<SiteGridRow> | undefined {
  const trial = useSelector(selectTrial);
  const trialTraceId = trial.trace_id;
  const dispatch = useDispatch();

  const { currentData: regions } = useGetRegionsByTrialQuery(trialTraceId);

  const [updateSite] = useUpdateSiteMutation();
  const [updateSiteContract] = useUpdateSiteContractMutation();
  const [addSiteVersion] = useCreateSiteContractMutation();
  const [upsertAdministrativeOrProcedureCost] =
    useUpsertAdministrativeOrProcedureCostMutation();
  const [upsertVisitCost] = useUpsertVisitCostMutation();
  const [deleteVisitCost] = useDeleteSafeVisitCostMutation();
  const [deleteAdministrativeOrProcedureCost] =
    useDeleteSafeAdministrativeOrProcedureCostMutation();
  const canEditTrialInfo = useHasPermission(['canEditTrialInfo']);
  return useMemo(() => {
    if (regions === undefined) {
      return undefined;
    }

    const onCellClicked = (event: CellClickedEvent) => {
      const { field } = event.colDef;
      if (field === 'number' && canEditTrialInfo) {
        dispatch(openEditSite({ traceId: event.data.site_trace_id }));
      }
    };

    const onCellValueChangedVisitCost = (params: CellValueChangedEvent) => {
      // users can copy and paste any value they want, so ignore "bad" values
      const cost = currencyToNumberFormatter(params.newValue);

      const requestParams = {
        site_or_lab_contract: params.data.site_contract_trace_id as TraceId,
        patient_assessment: params.colDef.cellEditorParams.trace_id,
      };

      if (Number.isNaN(cost)) {
        // because of how data can be added via copy and paste, we have to assume we don't already
        // have the correct cost trace_id and need to check via version and patient assessment
        void deleteVisitCost({ ...requestParams });
      } else {
        void upsertVisitCost({ ...requestParams, cost });
      }
    };

    const onCellValueChangedProcedureCost = (params: CellValueChangedEvent) => {
      // users can copy and paste any value they want, so ignore "bad" values
      const cost = currencyToNumberFormatter(params.newValue);

      const requestParams = {
        category_type: 'INV' as const,
        site_or_lab_contract: params.data.site_contract_trace_id as TraceId,
        category: params.colDef.cellEditorParams.trace_id,
      };

      if (Number.isNaN(cost)) {
        // because of how data can be added via copy and paste, we have to assume we don't already
        // have the correct cost trace_id and need to check via version and category
        void deleteAdministrativeOrProcedureCost({ ...requestParams });
      } else {
        void upsertAdministrativeOrProcedureCost({ ...requestParams, cost });
      }
    };

    const onCellValueChangedAdministrativeCost = (
      params: CellValueChangedEvent,
      columnName: string,
    ) => {
      // users can copy and paste any value they want, so ignore "bad" values
      const cost = currencyToNumberFormatter(params.newValue);

      const requestParams = {
        category_type: 'ADMIN' as const,
        site_or_lab_contract: params.data.site_contract_trace_id as TraceId,
        category: params.data[`${columnName}_cost_category`] as TraceId,
        category_name: columnName.replace('admin_', '').toUpperCase(),
        trial: trialTraceId,
      };

      if (Number.isNaN(cost)) {
        // because of how data can be added via copy and paste, we have to assume we don't already
        // have the correct cost trace_id and need to check via version and category
        void deleteAdministrativeOrProcedureCost({ ...requestParams });
      } else {
        void upsertAdministrativeOrProcedureCost({ ...requestParams, cost });
      }
    };

    const onCellValueChangedSiteContract = (
      params: CellValueChangedEvent,
      columnName: string,
    ) => {
      const { site_contract_trace_id } = params.data;
      void updateSiteContract({
        trace_id: site_contract_trace_id,
        [columnName]: formatValue(params.newValue),
        // if the vendor is changed, we need to reset the region
        ...(columnName === 'contract_container' ? { region: null } : {}),
      });
    };

    const onCellValueChangedSite = (
      params: CellValueChangedEvent,
      columnName: string,
    ) => {
      const { site_trace_id } = params.data;
      void updateSite({
        trace_id: site_trace_id,
        [columnName]: formatValue(params.newValue),
      });
    };

    const onCellValueChanged = (params: CellValueChangedEvent) => {
      const SITE_COLUMNS = ['recruited_date', 'initiated_date', 'closed_date'];
      const columnName = params.colDef.field ?? '';

      if (columnName.startsWith('admin_')) {
        onCellValueChangedAdministrativeCost(params, columnName);
      } else if (columnName.startsWith('procedure_')) {
        onCellValueChangedProcedureCost(params);
      } else if (columnName.startsWith('visit_')) {
        onCellValueChangedVisitCost(params);
      } else if (SITE_COLUMNS.includes(columnName)) {
        onCellValueChangedSite(params, columnName);
      } else {
        onCellValueChangedSiteContract(params, columnName);
      }

      // redraw the rows as the individual cellRenderer result might have changed
      // https://www.ag-grid.com/react-data-grid/view-refresh/#redraw-rows
      params.api.redrawRows();
    };

    const onCellFocused = (event: CellFocusedEvent) => {
      if (
        typeof event.column === 'string' ||
        typeof event.rowIndex !== 'number'
      ) {
        return;
      }

      const { vendor } =
        event.api.getDisplayedRowAtIndex(event.rowIndex)?.data ?? {};
      const colDef = event.column?.getColDef();
      if (
        !(vendor as TraceId | undefined) ||
        !colDef?.field ||
        colDef.field !== 'region'
      ) {
        return;
      }
      const { trialRegions: contextTrialRegions } =
        event.context as AgGridContext;
      const applicableRegions =
        contextTrialRegions
          ?.filter((region) => region.contract_container.vendor === vendor)
          .map(({ trace_id }) => trace_id) ?? [];
      applicableRegions.sort();
      colDef.cellEditorParams.values = applicableRegions;
    };

    const onAddNewSiteVersion = async (siteTraceId: TraceId) => {
      await addSiteVersion({ site: siteTraceId });
      onAddNewSiteVersionSuccess?.();
    };

    return {
      getRowId: 'site_contract_trace_id',
      ...(isOpenPeriod && {
        onCellClicked,
        onCellFocused,
        onCellValueChanged,
        context: { trialRegions: regions, onAddNewSiteVersion },
      }),
    };
  }, [
    regions,
    isOpenPeriod,
    dispatch,
    deleteVisitCost,
    upsertVisitCost,
    deleteAdministrativeOrProcedureCost,
    upsertAdministrativeOrProcedureCost,
    trialTraceId,
    updateSiteContract,
    updateSite,
    canEditTrialInfo,
  ]);
}

export type SiteCostMatrixGridContext = {
  trialRegions: RegionResponse[];
  onAddNewSiteVersion: (siteTraceId: TraceId) => Promise<void>;
};
