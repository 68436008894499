import { useMemo } from 'react';

import { skipToken } from '@reduxjs/toolkit/query';

import { calculateContractValues } from 'accruals/wizards/contract-version-wizard/AccountingInfoForm';
import {
  getBottomLineAdjustmentFromApiResponse,
  getContractCostCategoryInfosFromApiResult,
  getContractInfoFromApiResult,
} from 'accruals/wizards/contract-version-wizard/helpers';
import { currencyFormatter } from 'formatters';
import { humanize, humanizeContractVersion } from 'shared/helpers/helpers';
import type {
  BottomLineAdjustmentResponse,
  ContractContainerResponse,
  ContractCostCategoryInfoResponse,
  ContractVersionResponse,
  KeyValueRow,
} from 'shared/lib/types';

import { useGetBottomLineAdjustmentsByContractQuery } from 'shared/api/rtkq/bottomlineadjustments';
import { useGetContractCostCategoryInfoByContractVersionQuery } from 'shared/api/rtkq/contractcostcategoryinfo';

export function getContractVersionRecord(
  contractContainer: ContractContainerResponse | undefined,
  contractVersion: ContractVersionResponse | undefined,
  bottomLineAdjustments: BottomLineAdjustmentResponse[] | undefined,
  contractCostCategoryInfos: ContractCostCategoryInfoResponse[] | undefined,
) {
  const { totalNetValue, totalGrossValue } =
    contractContainer !== undefined && contractVersion !== undefined
      ? calculateContractValues(
          getContractInfoFromApiResult(
            contractVersion,
            bottomLineAdjustments?.map(
              getBottomLineAdjustmentFromApiResponse,
            ) ?? [],
            [],
            getContractCostCategoryInfosFromApiResult(
              contractCostCategoryInfos ?? [],
              contractContainer.vendor_type,
            ),
          ),
          contractContainer.vendor_type,
        )
      : { totalNetValue: undefined, totalGrossValue: undefined };

  const { currency } = contractContainer ?? {};

  return [
    {
      key: 'Current version',
      value: humanizeContractVersion(
        contractVersion?.version,
        contractVersion?.amendment_number,
      ),
    },
    { key: 'Version name', value: contractVersion?.version_name },
    { key: 'Effective date', value: contractVersion?.effective_date },
    { key: 'AIP Effective date', value: contractVersion?.aip_effective_date },
    { key: 'Void date', value: contractVersion?.voided_date },
    { key: 'PO number', value: contractVersion?.po_number },
    {
      key: 'Gross contract value',
      value: totalGrossValue
        ? currencyFormatter(totalGrossValue, currency)
        : undefined,
    },
    {
      key: 'Bottom line adjustments',
      value: bottomLineAdjustments?.map((adjustment) => {
        if (adjustment.amount_type === 'ABSOLUTE') {
          return `${humanize(adjustment.adjustment_type.toLowerCase())} ${currencyFormatter(
            adjustment.amount,
            currency,
          )}`;
        }
        return `${humanize(adjustment.adjustment_type.toLowerCase())} ${adjustment.amount_percentage}%`;
      }),
    },
    {
      key: 'Net contract value',
      value: totalNetValue
        ? currencyFormatter(totalNetValue, currency)
        : undefined,
    },
    {
      key: 'G/L accounts',
      value: contractCostCategoryInfos?.flatMap((info) =>
        info.gl_account ? [info.gl_account.account_number] : [],
      ),
    },
    { key: 'Bonuses & penalties', value: contractVersion?.bonus_and_penalties },
  ];
}

export default function useContractVersionRecord(
  contractContainer: ContractContainerResponse | undefined,
  contractVersion: ContractVersionResponse | undefined,
): KeyValueRow[] {
  const { currentData: bottomLineAdjustments } =
    useGetBottomLineAdjustmentsByContractQuery(
      contractVersion?.trace_id ?? skipToken,
    );

  const { currentData: contractCostCategoryInfos } =
    useGetContractCostCategoryInfoByContractVersionQuery(
      contractVersion?.trace_id ?? skipToken,
    );

  return useMemo(
    () =>
      getContractVersionRecord(
        contractContainer,
        contractVersion,
        bottomLineAdjustments,
        contractCostCategoryInfos,
      ),
    [
      bottomLineAdjustments,
      contractContainer,
      contractCostCategoryInfos,
      contractVersion,
    ],
  );
}
