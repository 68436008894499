import { useState } from 'react';

import FullWidthDialog from 'shared/lib/dialog/FullWidthDialog';
import Wizard from 'shared/lib/wizard/Wizard';

import LeavePageModal from './LeavePageModal';
import TrialRecordEditStep from './steps/TrialRecordEditStep';

type Props = {
  onClose: () => void;
};

function TrialSetupWizard(props: Props) {
  const { onClose } = props;

  const [showLeavePageModal, setShowLeavePageModal] = useState(false);

  const handleCloseBtnClick = () => {
    setShowLeavePageModal(true);
  };

  return (
    <FullWidthDialog open>
      <LeavePageModal
        isOpen={showLeavePageModal}
        setIsOpen={setShowLeavePageModal}
        onLeaveBtnClick={() => {
          onClose();
        }}
        onStayBtnClick={() => {
          setShowLeavePageModal(false);
        }}
      />
      <Wizard
        devTitle="FURY"
        title="Edit trial record"
        onClose={handleCloseBtnClick}
      >
        <TrialRecordEditStep />
      </Wizard>
    </FullWidthDialog>
  );
}

export default TrialSetupWizard;
