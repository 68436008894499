import { useParams } from 'react-router-dom';

import NavigationTabs from 'shared/components/tabs/NavigationTabs';

import { getAnalyticsTab } from 'routes';
import PageContentLoadingContainer from 'shared/containers/page-content-loading-container/PageContentLoadingContainer';

import withAccrualPageRequiredData from 'accruals/api/hocs/withAccrualPageRequiredData';

import AccrualsTabContent from './AccrualsTabContent';
import BsFluxTabContent from './BsFluxTabContent';

function AnalyticsPage() {
  const { activeTab } = useParams();

  const tabContent = activeTab
    ? {
        accruals: <AccrualsTabContent />,
        'balance-sheet-flux': <BsFluxTabContent />,
      }[activeTab]
    : undefined;

  return (
    <PageContentLoadingContainer
      tabs={
        <NavigationTabs
          activeTab={activeTab}
          generateUrl={getAnalyticsTab}
          tabs={[
            { key: 'accruals', devLabel: 'SHIELD', label: 'Accruals' },
            {
              key: 'balance-sheet-flux',
              devLabel: 'SHIELD',
              label: 'Balance Sheet Flux',
            },
          ]}
        />
      }
    >
      {tabContent}
    </PageContentLoadingContainer>
  );
}

export default withAccrualPageRequiredData(AnalyticsPage);
