import { useState } from 'react';

import PeopleOutline from '@mui/icons-material/PeopleOutline';
import Typography from '@mui/material/Typography';
import color from 'color';
import { useSelector } from 'react-redux';

import PageTitle, {
  periodToBreadcrumbString,
} from 'shared/components/page-titles/PageTitle';
import TrialTeamModal from 'shared/components/page-titles/components/TrialTeamModal';
import IconButton from 'shared/ui/icon-button/IconButton';

import { selectPeriod } from 'accruals/state/slices/periodSlice';
import colors from 'colors.module.scss';
import useFeatureFlag from 'shared/helpers/useFeatureFlag';
import useHasPermission from 'shared/lib/permissions/useHasPermission';
import { selectTrial } from 'shared/state/slices/trialSlice';
import { selectUserTraceId } from 'shared/state/slices/userSlice';

import TrialUploads from './trial-uploads/TrialUploads';

function AccrualsPageTitle() {
  const period = useSelector(selectPeriod);
  const trial = useSelector(selectTrial);
  const currentUser = useSelector(selectUserTraceId);
  const [showTrialTeam, setShowTrialTeam] = useState(false);
  const canEditTrialInfo = useHasPermission(['canEditTrialInfo']);

  const trialTeamModalEnabled = useFeatureFlag('trial_team');
  const trialUploadsPopperEnabled = useFeatureFlag('trial_uploads_popper');

  const reviewer = trial.reviewer?.trace_id === currentUser;
  const preparer = trial.preparer?.trace_id === currentUser;

  let indicator = null;
  if (reviewer || preparer || !canEditTrialInfo) {
    indicator = (
      <Typography
        component="p"
        variant="caption"
        sx={{
          ml: 1,
          px: 1,
          borderRadius: 0.5,
          ...(reviewer
            ? {
                backgroundColor: ({ palette }) =>
                  palette.mode === 'light'
                    ? color(colors.chart9).alpha(0.5).rgb().string()
                    : color(colors.chart10).alpha(0.2).rgb().string(),
                color: ({ palette }) =>
                  palette.mode === 'light' ? colors.chart10 : colors.chart9,
              }
            : preparer
              ? {
                  backgroundColor: ({ palette }) =>
                    color(palette.primary.main).alpha(0.15).rgb().string(),
                  color: ({ palette }) => palette.primary.main,
                }
              : {
                  backgroundColor: ({ palette }) => palette.grey[100],
                  color: ({ palette }) => palette.text.secondary,
                }),
        }}
      >
        {reviewer ? 'Reviewer' : preparer ? 'Preparer' : 'Read-only'}
      </Typography>
    );
  }

  return (
    <PageTitle
      breadcrumbs={[periodToBreadcrumbString(period)]}
      leftItems={indicator}
      rightItems={
        <>
          {trialTeamModalEnabled && (
            <>
              <IconButton onClick={() => setShowTrialTeam(true)}>
                <PeopleOutline />
              </IconButton>
              <TrialTeamModal
                setShowTrialTeam={setShowTrialTeam}
                showTrialTeam={showTrialTeam}
              />
            </>
          )}
          {trialUploadsPopperEnabled && <TrialUploads />}
        </>
      }
    />
  );
}

export default AccrualsPageTitle;
