import { useParams } from 'react-router-dom';

import NavigationTabs from 'shared/components/tabs/NavigationTabs';

import { getPatientNavigation } from 'routes';
import PageContentLoadingContainer from 'shared/containers/page-content-loading-container/PageContentLoadingContainer';

import withAccrualPageRequiredData from 'accruals/api/hocs/withAccrualPageRequiredData';

import PatientActivityPage from './patient-activity/PatientActivityPage';
import PatientJourneyPage from './patient-journey/PatientJourneyPage';

function PatientContainerPage() {
  const { activeTab } = useParams();

  const TABS = [
    { key: 'patient-activity', devLabel: 'HOUSE', label: 'Patient Activity' },
    { key: 'patient-journey', devLabel: 'SULLY', label: 'Patient Journey' },
  ];

  return (
    <PageContentLoadingContainer
      tabs={
        <NavigationTabs
          activeTab={activeTab}
          generateUrl={getPatientNavigation}
          tabs={TABS}
        />
      }
    >
      {activeTab === 'patient-activity' && <PatientActivityPage />}
      {activeTab === 'patient-journey' && <PatientJourneyPage />}
    </PageContentLoadingContainer>
  );
}

export default withAccrualPageRequiredData(PatientContainerPage);
