import { useMemo } from 'react';

import cloneDeep from 'lodash/cloneDeep';

import type {
  ContractContainerResponse,
  ContractVersionResponse,
  KeyValueRow,
} from 'shared/lib/types';

export function getContractContainerRecord(
  contractContainer: ContractContainerResponse | undefined,
  contractVersion: ContractVersionResponse | undefined,
) {
  const allPoNumbers = cloneDeep(contractContainer?.po_numbers) ?? [];
  if (
    contractVersion?.po_number &&
    !allPoNumbers.includes(contractVersion.po_number)
  ) {
    allPoNumbers.push(contractVersion.po_number);
  }

  return [
    { key: 'Contract type', value: contractContainer?.vendor_type },
    { key: 'Contract ID', value: contractContainer?.contract_id },
    { key: 'Vendor', value: contractContainer?.vendor_name },
    { key: 'PO Numbers', value: allPoNumbers.join(', ') },
    { key: 'Currency', value: contractContainer?.currency },
  ];
}

export default function useContractContainerRecord(
  contractContainer: ContractContainerResponse | undefined,
  contractVersion: ContractVersionResponse | undefined,
): KeyValueRow[] {
  return useMemo(
    () => getContractContainerRecord(contractContainer, contractVersion),
    [contractContainer, contractVersion],
  );
}
