import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import TertiaryNavigationTabs from 'shared/components/tabs/TertiaryNavigationTabs';

import useCohortAndPeriodWithVersions from 'accruals/pages/clinical-expenses/shared/hooks/useCohortAndPeriodWithVersions';
import { getPatientJourneyByCohortNavigation } from 'routes';
import type { PatientJourneyMenuItemType } from 'shared/lib/types';

function PatientJourneySubtabs() {
  const [activeTab, setActiveTab] = useState<string | undefined>();

  const {
    periodSpecificTabItems: finalCohortTabs,
    periodSpecificPatientCohortTraceId: patientCohortId,
  } = useCohortAndPeriodWithVersions();

  const navigate = useNavigate();

  useEffect(() => {
    const initialTab = patientCohortId ?? finalCohortTabs?.at(0)?.key;
    setActiveTab(initialTab);

    if (initialTab && !patientCohortId) {
      navigate(
        getPatientJourneyByCohortNavigation('patient-journey', initialTab),
      );
    }
  }, [finalCohortTabs, navigate, patientCohortId]);

  const tabs =
    finalCohortTabs?.map((eachCohort: PatientJourneyMenuItemType) => ({
      key: eachCohort.key,
      devLabel: 'BUNSHIN',
      label: eachCohort.title,
      disabled: false,
    })) ?? [];

  return (
    <TertiaryNavigationTabs
      activeTab={activeTab}
      tabs={tabs}
      generateUrl={(cohortId) =>
        getPatientJourneyByCohortNavigation('patient-journey', cohortId)
      }
    />
  );
}

export default PatientJourneySubtabs;
