import { type ReactNode, useEffect } from 'react';

import { useSelector } from 'react-redux';

import NoPermissions from 'shared/components/no-permissions/NoPermissions';
import Roller from 'shared/ui/roller/Roller';

import {
  missingPartner,
  selectPartner,
  selectPartnerInitialized,
  useInitializePartner,
} from 'shared/state/slices/partnerSlice';

import { useGetPartnersQuery } from '../rtkq/partners';

export default function withPartnerRequired<T extends object>(
  LiveDataComponent: (props: T) => ReactNode,
) {
  return function PartnerRequiredWrapper(props: T) {
    const initialized = useSelector(selectPartnerInitialized);
    const partner = useSelector(selectPartner);
    const initPartner = useInitializePartner();

    const {
      currentData: companyGroups,
      isUninitialized,
      isLoading,
      isFetching,
    } = useGetPartnersQuery();
    const waitForApi = isUninitialized || isLoading || isFetching;
    const numCompanyGroups = companyGroups?.length ?? 0;

    // only call initialize partner once
    useEffect(() => {
      if (waitForApi || initialized) {
        return;
      }

      initPartner(companyGroups);
    }, [companyGroups, waitForApi, initPartner, initialized]);

    if (!waitForApi && (partner === missingPartner || numCompanyGroups === 0)) {
      return <NoPermissions />;
    }

    return waitForApi ? <Roller /> : <LiveDataComponent {...props} />;
  };
}
