import { useEffect } from 'react';

import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import App from 'App';
import config from 'config';

if (config.VITE_APP_PAQARINA_SENTRY_DSN) {
  Sentry.init({
    dsn: config.VITE_APP_PAQARINA_SENTRY_DSN,
    environment: config.VITE_APP_NAME,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,

    // allow redux state to be more than 3 by default (Sentry recommends 10)
    normalizeDepth: 10,

    // only trace things that we control, not everything
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/api\..+\.condorsoftware\.com/,
    ],
  });
}

// biome-ignore lint/style/noNonNullAssertion: this is on index.html. it's guaranteed to be there
const root = createRoot(document.getElementById('root')!);
root.render(<App />);

if (config.IS_CYPRESS_ENVIRONMENT) {
  // eslint-disable-next-line no-console -- this is quite intentional to prove we are in a cypress environment
  console.log(
    '%c WE ARE RUNNING THE CONDOR APP WITHIN CYPRESS',
    'background: #CCC; color: blue; font-size: 24px; padding: 10px;',
  );
}
