import type {
  ContractVersionRequestParams,
  ContractVersionResponse,
  TraceId,
} from 'shared/lib/types';

import {
  useCreateContractMutation,
  useUpdateContractMutation,
} from 'shared/api/rtkq/contracts';

function useUpsertContractMutation(): [
  (
    data: ContractVersionRequestParams,
    traceId?: TraceId,
  ) => Promise<ContractVersionResponse>,
  { isLoading: boolean },
] {
  const [createContractRequest, { isLoading: createIsLoading }] =
    useCreateContractMutation();
  const [updateContractRequest, { isLoading: updateIsLoading }] =
    useUpdateContractMutation();

  async function upsertContract(
    data: ContractVersionRequestParams,
    traceId?: TraceId,
  ) {
    if (traceId) {
      return updateContractRequest({ trace_id: traceId, ...data }).unwrap();
    }

    return createContractRequest(data).unwrap();
  }

  return [upsertContract, { isLoading: createIsLoading || updateIsLoading }];
}

export default useUpsertContractMutation;
