import { useMemo } from 'react';

import HistoricInvoiceListingHeader from './components/HistoricInvoiceListingHeader';
import InvoiceListingGrid from './grids/InvoiceListingGrid';

function HistoricInvoiceListing() {
  return (
    <>
      <HistoricInvoiceListingHeader />
      <InvoiceListingGrid
        overlayNoRowsTemplate="No invoices added."
        sx={useMemo(() => ({ height: '100%' }), [])}
      />
    </>
  );
}

export default HistoricInvoiceListing;
