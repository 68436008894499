import { useMemo } from 'react';

import cloneDeep from 'lodash/cloneDeep';

import type { CondorGridOptions } from 'shared/components/ag-grid/types';

import type {
  ContractContainerPeriodMenuItem,
  ContractContainerResponse,
} from 'shared/lib/types';

export function processOccVersionHistoryGridOptions(
  isOpenPeriod: boolean,
  contractContainers: ContractContainerResponse[] | undefined,
  contractContainerPeriodMenuItems?: ContractContainerPeriodMenuItem[],
): CondorGridOptions {
  const clonedContractContainers = cloneDeep(contractContainers ?? []);

  // when period closed, switch the id to period menu item INSTEAD OF contract container
  // so everything links correctly when clicking the row (as period closed doesn't use
  // the real contract container objects as we can't promise they won't be deleted in the future)
  const innerContractContainers = clonedContractContainers.flatMap(
    (contractContainer) => {
      const contractContainerPeriodMenuItem =
        contractContainerPeriodMenuItems?.find(
          (menuItem) =>
            menuItem.contractContainer.trace_id === contractContainer.trace_id,
        );

      return {
        ...contractContainer,
        trace_id:
          contractContainerPeriodMenuItem?.menuItem.trace_id ??
          contractContainer.trace_id,
      };
    },
  );

  return {
    groupDisplayType: 'groupRows',
    groupRowRendererParams: {
      innerRenderer: 'AgGridOccContractContainerGroupCellRenderer',
      innerRendererParams: {
        contractContainers: innerContractContainers,
        isOpenPeriod,
      },
      suppressCount: true,
    },
    rowSelection: {
      mode: 'singleRow',
      checkboxes: false,
      enableClickSelection: true,
    },
    rowStyle: { cursor: 'pointer' },
  };
}

export default function useOccVersionHistoryGridOptions(
  isOpenPeriod: boolean,
  contractContainers: ContractContainerResponse[] | undefined,
): CondorGridOptions {
  return useMemo(
    () =>
      processOccVersionHistoryGridOptions(
        isOpenPeriod,
        contractContainers,
        undefined,
      ),
    [isOpenPeriod, contractContainers],
  );
}
