import { type ReactElement, useCallback, useContext } from 'react';

import DarkModeIcon from '@mui/icons-material/DarkMode';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import LightModeIcon from '@mui/icons-material/LightMode';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';

import Button from 'shared/ui/button/Button';

import ThemeContext from 'shared/containers/theme-context/ThemeContext';

import useIsCondorEmployee from 'shared/api/hooks/useIsCondorEmployee';

function ThemeMode(): ReactElement | null {
  const { themeMode, setThemeMode } = useContext(ThemeContext);
  const isCondorEmployee = useIsCondorEmployee();

  const toggleThemeMode = useCallback(() => {
    setThemeMode((prevMode) => {
      const updatedMode = prevMode === 'light' ? 'dark' : 'light';
      localStorage.setItem('theme', updatedMode);
      return updatedMode;
    });
  }, [setThemeMode]);

  return isCondorEmployee ? (
    <>
      <Divider orientation="vertical" sx={{ my: 1.5, mr: 2 }} flexItem />
      <Button
        sx={{ bgcolor: 'grey.100' }}
        testId="DarkModeToggle"
        onClick={toggleThemeMode}
      >
        <Stack direction="row" spacing={0.5}>
          {themeMode === 'light' ? (
            <LightModeIcon sx={{ color: 'primary.main' }} />
          ) : (
            <LightModeOutlinedIcon sx={{ color: 'text.secondary' }} />
          )}
          {themeMode === 'dark' ? (
            <DarkModeIcon sx={{ color: 'primary.main' }} />
          ) : (
            <DarkModeOutlinedIcon sx={{ color: 'text.secondary' }} />
          )}
        </Stack>
      </Button>
    </>
  ) : null;
}
export default ThemeMode;
