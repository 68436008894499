import { useCallback, useMemo } from 'react';

import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import GridRows from '@visx/grid/lib/grids/GridRows';
import Group from '@visx/group/lib/Group';
import AnimatedAxis from '@visx/react-spring/lib/axis/AnimatedAxis';
import ordinal from '@visx/scale/lib/scales/ordinal';
import type { AnyD3Scale } from '@visx/scale/lib/types/Scale';
import Line from '@visx/shape/lib/shapes/Line';
import Text from '@visx/text/lib/Text';
import useTooltip from '@visx/tooltip/lib//hooks/useTooltip';
import type { ScaleBand } from 'd3-scale';
import { useSelector } from 'react-redux';

import { TRIAL_SPEND_HEIGHT } from 'forecasting/components/graphing/trial-spend-forecast-graph/TrialSpendForecastGraph';

import { getCurrencySymbol } from 'formatters';
import { getFixedMinimumValues } from 'shared/lib/graphing/graphUtils';
import { isAllocatedPeriod } from 'shared/lib/graphing/helper';
import CondorAnimatedBarSeries from 'shared/lib/graphing/series/CondorAnimatedBarSeries';
import CondorAnimatedBarStack from 'shared/lib/graphing/series/CondorAnimatedBarStack';
import useStackBar from 'shared/lib/graphing/series/useStackBar';
import GraphLegend from 'shared/lib/graphing/shared/GraphLegend';
import useTimeAxisLabels from 'shared/lib/graphing/shared/useTimeAxisLabels';
import ZeroLine from 'shared/lib/graphing/shared/ZeroLine';
import { selectTrial } from 'shared/state/slices/trialSlice';
import { filterUndefined, parseNullableInt } from 'utils';

import GraphTooltipDataProvider from '../../../../shared/lib/graphing/graph-tooltip/GraphTooltipDataProvider';
import { COST_PER_PATIENT_GRAPH_CONFIG } from './config';
import CostPerPatientGraphTooltip from './CostPerPatientGraphTooltip';
import type { CostPerPatientConfig, CostPerPatientDatum } from './types';

type Props = {
  expectedCostPerPatient: string | undefined;
  graphData: CostPerPatientDatum[] | undefined;
  graphOptions?: CostPerPatientConfig;
  latestCloseDate: string | undefined;
  width: number;
};

function CostPerPatientGraph(props: Props) {
  const {
    graphData,
    graphOptions = COST_PER_PATIENT_GRAPH_CONFIG,
    expectedCostPerPatient,
    latestCloseDate,
    width,
  } = props;
  const tooltip = useTooltip<CostPerPatientDatum>();
  const themeMode = useTheme().palette.mode;
  const trialCurrency = useSelector(selectTrial).currency;

  const timeAxisLabels = useTimeAxisLabels(graphData?.length);
  const { xScale, yScale } = useStackBar({
    height: TRIAL_SPEND_HEIGHT,
    width,
    graphOptions,
    graphData,
  });
  const showZeroLine = yScale?.domain().some((item) => item < 0);
  const { margin } = graphOptions;

  const left = parseNullableInt(margin?.left);
  const right = parseNullableInt(margin?.right);
  const top = parseNullableInt(margin?.top);
  const bottom = parseNullableInt(margin?.bottom);

  const innerWidth = width - left - right;
  const innerHeight = TRIAL_SPEND_HEIGHT - top - bottom;
  const accessorsX = useMemo(
    () => (item: CostPerPatientDatum) => item.date,
    [],
  );
  const barStackData = useMemo(() => {
    if (!graphData) {
      return [];
    }

    function getSumInMonth(item: CostPerPatientDatum) {
      return [
        item.actualSpend === null
          ? null
          : Number.parseInt(item.actualSpend, 10),
        item.forecastedSpend === null
          ? null
          : Number.parseInt(item.forecastedSpend, 10),
      ];
    }

    const onePercent =
      Math.max(...(graphData.flatMap(getSumInMonth) as number[])) / 100;
    return getFixedMinimumValues(
      onePercent * 2,
      graphData,
      filterUndefined(graphOptions.orderOfData),
    );
  }, [graphData, graphOptions.orderOfData]);

  const accessorsY = useCallback(
    (item: CostPerPatientDatum) => {
      const row = barStackData.find(
        (mappedItem) => mappedItem.date === item.date,
      );
      const result =
        row !== undefined
          ? item.actual
            ? row.actualSpend
            : row.forecastedSpend
          : null;
      return result ? Number.parseInt(result, 10) : result;
    },
    [barStackData],
  );

  const legendShapes = useCallback(
    (datum: ReturnType<AnyD3Scale>) => {
      if ([graphOptions.budgetedCostPerPatient].includes(datum.text)) {
        return { type: 'line' as const };
      }

      return {
        type: 'rect' as const,
        stroke:
          datum.text === graphOptions.forecastedSpendText
            ? graphOptions.forecastedSpendColorOuter
            : undefined,
      };
    },
    [
      graphOptions.budgetedCostPerPatient,
      graphOptions.forecastedSpendColorOuter,
      graphOptions.forecastedSpendText,
    ],
  );

  if (graphData === undefined || graphData.length === 0) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 'calc(100% - 48px)',
        }}
      >
        <Box>
          {graphData === undefined
            ? 'Please wait... loading.'
            : 'Cost per patient chart will appear when patients are available.'}
        </Box>
      </Box>
    );
  }

  const legendColorScale = ordinal<string, string>({
    domain: filterUndefined<string>([
      graphOptions.actualSpendText,
      graphOptions.forecastedSpendText,
      graphOptions.budgetedCostPerPatient,
    ]),
    range: filterUndefined<string>([
      graphOptions.actualBarColor,
      graphOptions.forecastedBarColor,
      graphOptions.actualEnrollmentCurveColor,
    ]),
  });

  if (!yScale || !xScale) {
    return null;
  }
  const trialCurrencySymbol = getCurrencySymbol(trialCurrency);

  return (
    <Box sx={{ display: 'grid', height: '100%' }}>
      <GraphLegend colorScale={legendColorScale} shapes={legendShapes} />
      <Box sx={{ width: '100%' }}>
        <svg height={TRIAL_SPEND_HEIGHT} width={width}>
          <GridRows
            left={left}
            scale={yScale}
            stroke={graphOptions.horizontalLinesColor}
            width={innerWidth}
          />
          <Group>
            <Text
              angle={270}
              dx={40}
              dy={160}
              fontSize={graphOptions.fontSize}
              fontWeight={graphOptions.fontWeightBold}
              textAnchor="start"
            >
              {`Cost per patient (${trialCurrencySymbol})`}
            </Text>
            <AnimatedAxis
              animationTrajectory="min"
              hideZero={!showZeroLine}
              left={left}
              orientation="left"
              scale={yScale}
              stroke={graphOptions.horizontalLinesColor}
              tickStroke={graphOptions.horizontalLinesColor}
              tickLabelProps={() => ({
                dx: -10,
                fontSize: graphOptions.fontSize,
                fill: graphOptions.textColor,
                fontWeight: graphOptions.fontWeight,
                textAnchor: 'end',
                verticalAnchor: 'middle',
              })}
              hideAxisLine
              hideTicks
            />
          </Group>

          <AnimatedAxis
            key="axis-bottom"
            animationTrajectory="min"
            numTicks={timeAxisLabels.numTicks}
            orientation="bottom"
            scale={xScale}
            stroke={graphOptions.horizontalLinesColor}
            strokeWidth={1}
            tickLength={10}
            top={TRIAL_SPEND_HEIGHT - bottom}
            tickComponent={({ formattedValue }) => (
              <g transform="translate(0, 10)">
                <text
                  fontSize={graphOptions.fontSize}
                  textAnchor="middle"
                  fill={
                    isAllocatedPeriod(formattedValue, latestCloseDate)
                      ? graphOptions.textColor
                      : 'red'
                  }
                  transform={
                    timeAxisLabels.autoLabels ? '' : graphOptions.angleLabels
                  }
                >
                  {timeAxisLabels.formatMonthLabel(formattedValue)}
                </text>
                <text
                  dy={timeAxisLabels.yearLabelDy}
                  fontSize={graphOptions.fontSizeYear}
                  fontWeight={graphOptions.fontWeightBold}
                  textAnchor="middle"
                  fill={
                    isAllocatedPeriod(formattedValue, latestCloseDate)
                      ? graphOptions.textColor
                      : 'red'
                  }
                >
                  {timeAxisLabels.formatYearLabel(formattedValue)}
                </text>
              </g>
            )}
          />
          <CondorAnimatedBarStack xScale={xScale} yScale={yScale}>
            <CondorAnimatedBarSeries
              data={barStackData}
              dataKey="bar"
              strokeWidthAccessor={(item) => (item.actual ? undefined : 0.5)}
              xAccessor={accessorsX}
              yAccessor={accessorsY}
              colorAccessor={(item) =>
                item.actual
                  ? graphOptions.actualBarColor
                  : graphOptions.forecastedBarColor
              }
              strokeAccessor={(item) =>
                item.actual ? undefined : graphOptions.forecastedSpendColorOuter
              }
            />
          </CondorAnimatedBarStack>
          {!!expectedCostPerPatient && (
            <Line
              stroke={graphOptions.actualEnrollmentCurveColor}
              strokeWidth={1.5}
              from={{
                x: left,
                y: yScale(Number.parseInt(expectedCostPerPatient)),
              }}
              style={{
                mixBlendMode: themeMode === 'light' ? 'multiply' : 'normal',
              }}
              to={{
                x: width - right,
                y: yScale(Number.parseInt(expectedCostPerPatient)),
              }}
            />
          )}
          {showZeroLine && (
            <ZeroLine
              from={{ x: left, y: yScale(0) }}
              to={{ x: width - right, y: yScale(0) }}
            />
          )}
          <GraphTooltipDataProvider<CostPerPatientDatum, ScaleBand<string>>
            graphData={graphData}
            height={innerHeight}
            margin={{ top, left, right, bottom }}
            tooltip={tooltip}
            width={innerWidth}
            xAccessor={accessorsX}
            xScale={xScale as ScaleBand<string>}
          />
        </svg>
        <CostPerPatientGraphTooltip
          budgetedCost={expectedCostPerPatient}
          budgetedCostLabel={graphOptions.budgetedCostPerPatient}
          currency={trialCurrency}
          innerHeight={innerHeight}
          legendColorScale={legendColorScale}
          marginTop={top}
          orderOfData={graphOptions.orderOfData}
          tooltip={tooltip}
        />
      </Box>
    </Box>
  );
}

export default CostPerPatientGraph;
