import type { ButtonProps } from 'shared/ui/button/Button';
import Button from 'shared/ui/button/Button';

import styles from './DashboardButton.module.scss';

export default function DashboardButton(props: ButtonProps) {
  return (
    <Button
      {...props}
      className={styles.button}
      size="small"
      variant="outlined"
    />
  );
}
