/*
██████╗░██╗░░░░░███████╗░█████╗░░██████╗███████╗  ██████╗░███████╗░█████╗░██████╗░
██╔══██╗██║░░░░░██╔════╝██╔══██╗██╔════╝██╔════╝  ██╔══██╗██╔════╝██╔══██╗██╔══██╗
██████╔╝██║░░░░░█████╗░░███████║╚█████╗░█████╗░░  ██████╔╝█████╗░░███████║██║░░██║
██╔═══╝░██║░░░░░██╔══╝░░██╔══██║░╚═══██╗██╔══╝░░  ██╔══██╗██╔══╝░░██╔══██║██║░░██║
██║░░░░░███████╗███████╗██║░░██║██████╔╝███████╗  ██║░░██║███████╗██║░░██║██████╔╝
╚═╝░░░░░╚══════╝╚══════╝╚═╝░░╚═╝╚═════╝░╚══════╝  ╚═╝░░╚═╝╚══════╝╚═╝░░╚═╝╚═════╝░

This slice should be used in conjunction with the CompanyRequiredLayout component
that knows how to handle when company is null / undefined, so we can assume
that company is always defined inside that element, greatly simplifying the codebase.

If you use this slice outside of the CompanyRequiredLayout context, it will NOT handle 
the "missing company" case correctly and is likely a sign you are doing something wrong 
(or need to move where CompanyRequiredLayout is being used in the routes).

If you have any questions, please ask in #engineering
*/

import { useCallback } from 'react';

import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import type { CompanyResponse } from 'shared/lib/types';
import type { RootState } from 'shared/state/store';
/* eslint-disable import/no-restricted-paths -- company is special as everything uses it */
import { useReinitializePeriod } from 'accruals/state/slices/periodSlice';
import { useReinitializePeriodVersion } from 'accruals/state/slices/periodVersionSlice';
import { useReinitializeForecast } from 'forecasting/state/slices/forecastSlice';
/* eslint-enable import/no-restricted-paths */

import baseApi from 'shared/api/baseApi';
import { getRegisteredTags } from 'shared/api/rtkq/constructApi';

import { useReinitializeTrial } from './trialSlice';
import { getCompanySafeKeys } from './utils';

type State = { company: CompanyResponse; initialized: boolean };

const missingCompany = null as unknown as CompanyResponse;

const initialState: State = { company: missingCompany, initialized: false };

const COMPANY_KEY = 'company_trace_id';

const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    changeCompany: (state, action: PayloadAction<CompanyResponse>) => {
      state.company = action.payload;
    },
    changeInitialized: (state, action: PayloadAction<boolean>) => {
      state.initialized = action.payload;
    },
  },
});

const { changeCompany, changeInitialized } = companySlice.actions;

export { missingCompany };

export const selectCompany = (state: RootState) => state.company.company;
export const selectCompanyInitialized = (state: RootState) =>
  state.company.initialized;

export const companyReducer = companySlice.reducer;

export function useChangeCompany() {
  const dispatch = useDispatch();
  const reInitTrial = useReinitializeTrial();

  const reInitForecast = useReinitializeForecast();
  const reInitPeriod = useReinitializePeriod();
  const reInitPeriodVersion = useReinitializePeriodVersion();

  return useCallback(
    (company: CompanyResponse | null, isInit = false) => {
      if (company === null) {
        localStorage.removeItem(COMPANY_KEY);
        dispatch(changeCompany(missingCompany));
      } else {
        localStorage.setItem(COMPANY_KEY, company.trace_id);
        dispatch(changeCompany(company));
      }

      // clear all existing RTKQ state to guarantee we're not using stale data, although we
      // don't clear "safe" keys that cannot change, so it reduces loading states.
      //
      // additionally, re-initialize the things "inside" company so everything updates correctly
      if (!isInit) {
        reInitTrial();
        reInitForecast();
        reInitPeriod();
        reInitPeriodVersion();

        const tagsToClear = getRegisteredTags().filter(
          (tag) => !getCompanySafeKeys().includes(tag),
        );
        dispatch(baseApi.util.invalidateTags(tagsToClear));
      }
    },
    [dispatch, reInitForecast, reInitPeriod, reInitPeriodVersion, reInitTrial],
  );
}

export function useInitializeCompany() {
  const change = useChangeCompany();
  const dispatch = useDispatch();

  return useCallback(
    (companies: CompanyResponse[] | undefined) => {
      dispatch(changeInitialized(true));

      // if we have no returned companies, ensure we update state in case what was present was for a company they don't have access to
      let company = missingCompany;
      if (companies?.length) {
        const previousCompanyId = localStorage.getItem(COMPANY_KEY) ?? null;
        const previousCompany = companies.find(
          (comp) => comp.trace_id === previousCompanyId,
        );
        // If we didn't find a relevant selected company in local storage, arbitrarily default to the first one in the list.
        company = previousCompany ?? companies[0];
      }

      change(company, true);
    },
    [change, dispatch],
  );
}
