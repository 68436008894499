import type { ChangeEvent, SyntheticEvent } from 'react';
import { useEffect, useState } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';

import CondorTextField from 'shared/components/text-field/CondorTextField';
import Autocomplete from 'shared/ui/autocomplete/Autocomplete';
import Button from 'shared/ui/button/Button';

import DialogTitleWithClose from 'shared/lib/dialog/DialogTitleWithClose';
import type {
  BottomLineAdjustment,
  BottomLineAdjustmentAmountType,
  BottomLineAdjustmentType,
  ContractInfo,
  DropdownOption,
} from 'shared/lib/types';

import { useDeleteBottomLineAdjustmentMutation } from 'shared/api/rtkq/bottomlineadjustments';

type Props = {
  bottomLineAdjustmentIndexToEdit: number | null;
  contractInfo: ContractInfo;
  isOpen: boolean;
  setBottomLineAdjustmentIndexToEdit: (index: number | null) => void;
  setContractInfo: (contractInfo: ContractInfo) => void;
  onClose: () => void;
};

const BottomLineAdjustmentTypes: Array<
  DropdownOption<BottomLineAdjustmentType>
> = [
  { label: 'Discount', value: 'DISCOUNT' },
  { label: 'Rebate', value: 'REBATE' },
  { label: 'Tax Rate', value: 'TAX_RATE' },
  { label: 'Inflation', value: 'INFLATION' },
];

export default function BottomLineAdjustmentDialog(props: Props) {
  const {
    isOpen,
    onClose,
    contractInfo,
    setContractInfo,
    bottomLineAdjustmentIndexToEdit,
    setBottomLineAdjustmentIndexToEdit,
  } = props;
  const [valid, setValid] = useState(false);
  const [adjustment, setAdjustment] = useState<BottomLineAdjustment>(
    bottomLineAdjustmentIndexToEdit
      ? contractInfo.accountInfo.bottomLineAdjustments[
          bottomLineAdjustmentIndexToEdit
        ]
      : {
          adjustmentType: 'DISCOUNT',
          amountType: 'ABSOLUTE',
          amount: 0,
          amountPercentage: 0,
        },
  );

  const [deleteBottomLineAdjustment] = useDeleteBottomLineAdjustmentMutation();

  useEffect(() => {
    if (bottomLineAdjustmentIndexToEdit !== null) {
      setAdjustment(
        contractInfo.accountInfo.bottomLineAdjustments[
          bottomLineAdjustmentIndexToEdit
        ],
      );
    }
  }, [
    bottomLineAdjustmentIndexToEdit,
    contractInfo.accountInfo.bottomLineAdjustments,
  ]);

  useEffect(() => {
    if (!isOpen) {
      setAdjustment({
        adjustmentType: 'DISCOUNT',
        amountType: 'ABSOLUTE',
        amount: 0,
        amountPercentage: 0,
      });
      setBottomLineAdjustmentIndexToEdit(null);
    }
  }, [isOpen, setBottomLineAdjustmentIndexToEdit]);

  // validations
  useEffect(() => {
    if (!adjustment.adjustmentType) {
      setValid(false);
      return;
    }
    if (adjustment.amountType === 'ABSOLUTE') {
      if (adjustment.amount && adjustment.amount <= 0) {
        setValid(false);
        return;
      }
    } else if (
      adjustment.amountPercentage &&
      (adjustment.amountPercentage === 0 || adjustment.amountPercentage > 100)
    ) {
      setValid(false);
      return;
    }
    setValid(true);
  }, [adjustment]);

  function handleAdjustmentTypeChange(
    _event: SyntheticEvent,
    value: DropdownOption<BottomLineAdjustmentType> | null,
  ) {
    if (value) {
      setAdjustment({ ...adjustment, adjustmentType: value.value });
    }
  }

  function handleAdjustmentAmountTypeChange(
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) {
    const amountType = event.target.value;
    const newAdjustment = {
      ...adjustment,
      amountType: amountType as BottomLineAdjustmentAmountType,
    };
    if (newAdjustment.amountType === 'ABSOLUTE') {
      newAdjustment.amountPercentage = undefined;
    } else {
      newAdjustment.amount = undefined;
    }
    setAdjustment(newAdjustment);
  }

  function handleAdjustmentAmountChange(
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) {
    const { name, value } = event.target;
    setAdjustment({ ...adjustment, [name]: Number.parseFloat(value) });
  }

  function getSelectedAdjustmentType() {
    return BottomLineAdjustmentTypes.find(
      (type) => type.value === adjustment.adjustmentType,
    );
  }

  function onSave() {
    if (bottomLineAdjustmentIndexToEdit !== null) {
      const newAdjustments = [
        ...contractInfo.accountInfo.bottomLineAdjustments,
      ];
      newAdjustments[bottomLineAdjustmentIndexToEdit] = adjustment;
      setContractInfo({
        ...contractInfo,
        accountInfo: {
          ...contractInfo.accountInfo,
          bottomLineAdjustments: newAdjustments,
        },
      });
    } else {
      setContractInfo({
        ...contractInfo,
        accountInfo: {
          ...contractInfo.accountInfo,
          bottomLineAdjustments: [
            ...contractInfo.accountInfo.bottomLineAdjustments,
            adjustment,
          ],
        },
      });
    }
    onClose();
  }

  async function deleteAdjustment() {
    if (bottomLineAdjustmentIndexToEdit !== null) {
      const newAdjustments = [
        ...contractInfo.accountInfo.bottomLineAdjustments,
      ];
      const adjustmentToRemove = newAdjustments.splice(
        bottomLineAdjustmentIndexToEdit,
        1,
      );
      if (adjustmentToRemove[0]?.traceId) {
        try {
          await deleteBottomLineAdjustment(
            adjustmentToRemove[0]?.traceId,
          ).unwrap();
        } catch {
          // ?: Handle error?
          return;
        }
      }
      setContractInfo({
        ...contractInfo,
        accountInfo: {
          ...contractInfo.accountInfo,
          bottomLineAdjustments: newAdjustments,
        },
      });
    }
    onClose();
  }

  return (
    <Dialog maxWidth="sm" open={isOpen}>
      <DialogTitleWithClose onClose={onClose}>
        <Typography variant="subtitle1">Bottom line adjustments</Typography>
      </DialogTitleWithClose>
      <DialogContent>
        <>
          <Typography sx={{ marginBottom: 2 }}>
            This will be applied to the direct fees gross value.
          </Typography>
          <RadioGroup
            name="amountType"
            value={adjustment.amountType}
            onChange={handleAdjustmentAmountTypeChange}
          >
            <div
              style={{
                marginBottom: '22px',
                display: 'inline-flex',
                alignItems: 'center',
              }}
            >
              <FormControl sx={{ width: '100%' }}>
                <Autocomplete
                  label="Select type"
                  options={BottomLineAdjustmentTypes}
                  value={getSelectedAdjustmentType()}
                  onChange={handleAdjustmentTypeChange}
                />
              </FormControl>
            </div>
            <Grid spacing={2} container>
              <Grid xs={4} item>
                <FormControlLabel
                  control={<Radio size="small" />}
                  label="Amount"
                  value="ABSOLUTE"
                />
              </Grid>
              <Grid xs={8} item>
                <FormControl sx={{ width: '100%' }}>
                  <CondorTextField
                    disabled={adjustment.amountType === 'PERCENT'}
                    label="Enter amount"
                    name="amount"
                    type="number"
                    value={adjustment.amount ?? ''}
                    onChange={handleAdjustmentAmountChange}
                  />
                </FormControl>
              </Grid>
              <Grid xs={4} item>
                <FormControlLabel
                  control={<Radio size="small" />}
                  label="Percentage"
                  value="PERCENT"
                />
              </Grid>
              <Grid xs={8} item>
                <FormControl sx={{ width: '100%' }}>
                  <CondorTextField
                    disabled={adjustment.amountType === 'ABSOLUTE'}
                    label="Enter percentage"
                    name="amountPercentage"
                    type="number"
                    value={adjustment.amountPercentage ?? ''}
                    onChange={handleAdjustmentAmountChange}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </RadioGroup>
        </>
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'flex-end' }}>
        {bottomLineAdjustmentIndexToEdit !== null && (
          <Button
            sx={{ marginRight: 'auto' }}
            testId="delete"
            variant="text"
            onClick={() => void deleteAdjustment()}
          >
            Delete
          </Button>
        )}

        <Button testId="cancel" variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button
          disabled={!valid}
          testId="save"
          variant="contained"
          onClick={() => onSave()}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
