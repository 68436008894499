import CheckIcon from '@mui/icons-material/Check';
import Close from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import { useDispatch, useSelector } from 'react-redux';

import IconButton from 'shared/ui/icon-button/IconButton';

import {
  selectCommentsHeader,
  selectCommentsTitle,
  selectShowPriorPeriodComments,
  updateShowPriorPeriodComments,
} from 'shared/state/slices/commentSlice';

import KabobMenu from '../kabob-menu/KabobMenu';

type Props = { isForTrial: boolean; onClose?: () => void };

function CommentsHeader(props: Props) {
  const { onClose, isForTrial } = props;
  const header = useSelector(selectCommentsHeader);
  const title = useSelector(selectCommentsTitle);
  const showPriorPeriodComments = useSelector(selectShowPriorPeriodComments);
  const dispatch = useDispatch();
  return (
    <Box
      sx={{
        p: 2,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        bgcolor: 'grey.100',
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
      }}
    >
      <Box flex={1}>
        {!!title && (
          <Typography
            color="text.secondary"
            sx={{ textTransform: 'uppercase' }}
            variant="subtitle2"
          >
            {title}
          </Typography>
        )}
        {!!header && <Typography variant="subtitle1">{header}</Typography>}
      </Box>
      {isForTrial && (
        <KabobMenu
          options={[
            {
              label: 'Show prior period comments',
              icon: showPriorPeriodComments ? <CheckIcon /> : null,
              onClick: () => {
                dispatch(
                  updateShowPriorPeriodComments({
                    showPriorPeriodComments: !showPriorPeriodComments,
                  }),
                );
              },
            },
          ]}
        />
      )}
      {onClose && (
        <IconButton variant="tertiary" onClick={onClose}>
          <Close />
        </IconButton>
      )}
    </Box>
  );
}

export default CommentsHeader;
