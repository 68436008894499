/*
██████╗░██╗░░░░░███████╗░█████╗░░██████╗███████╗  ██████╗░███████╗░█████╗░██████╗░
██╔══██╗██║░░░░░██╔════╝██╔══██╗██╔════╝██╔════╝  ██╔══██╗██╔════╝██╔══██╗██╔══██╗
██████╔╝██║░░░░░█████╗░░███████║╚█████╗░█████╗░░  ██████╔╝█████╗░░███████║██║░░██║
██╔═══╝░██║░░░░░██╔══╝░░██╔══██║░╚═══██╗██╔══╝░░  ██╔══██╗██╔══╝░░██╔══██║██║░░██║
██║░░░░░███████╗███████╗██║░░██║██████╔╝███████╗  ██║░░██║███████╗██║░░██║██████╔╝
╚═╝░░░░░╚══════╝╚══════╝╚═╝░░╚═╝╚═════╝░╚══════╝  ╚═╝░░╚═╝╚══════╝╚═╝░░╚═╝╚═════╝░

This layout is to be used only for pages which do NOT belong to any specific company or a trial, and only partner users should be able to access it.

This is a XXXLayout instead of a container as we only want this to render once, and not unmount
when we stay within the partner pages so we don't cause the menu to flicker.
*/

import { Outlet } from 'react-router-dom';

import PageContentContainer from 'shared/containers/page-content-container/PageContentContainer';
import PartnerPageContainer from 'shared/containers/partner-page-container/PartnerPageContainer';

import withPartnerRequired from 'shared/api/hocs/withPartnerRequired';

import PartnerSidebar from '../../components/sidebar/PartnerSidebar';

function PartnerLayout() {
  return (
    <PartnerPageContainer>
      <PartnerSidebar />
      <PageContentContainer>
        <Outlet />
      </PageContentContainer>
    </PartnerPageContainer>
  );
}

export default withPartnerRequired(PartnerLayout);
