import { type ReactElement, useEffect, useRef } from 'react';

import type { SxProps } from '@mui/material/styles';
import { skipToken } from '@reduxjs/toolkit/query';

import type {
  AgGridRef,
  UserDisplayOptions,
} from 'shared/components/ag-grid/CondorAgGrid';
import CondorAgGrid from 'shared/components/ag-grid/CondorAgGrid';
import useGridColDefs from 'shared/components/ag-grid/hooks/useGridColDefs';
import useGridOptions from 'shared/components/ag-grid/hooks/useGridOptions';
import useLockedGridStatus from 'shared/components/lock-grid/useLockedGridStatus';

import useOccReconGridOptions from 'accruals/pages/clinical-expenses/occ/hooks/useOccReconGridOptions';
import useContractAndPeriodWithVersions from 'accruals/pages/clinical-expenses/shared/hooks/useContractAndPeriodWithVersions';
import withPeriodSpecificGridWrapper from 'shared/lib/periods/withPeriodSpecificGridWrapper';
import { LockGrid, PeriodGridBlobType } from 'shared/lib/types';

import { useGetOccReconGridQuery } from 'shared/api/rtkq/periods';

import useOccReconGridColumnDefs from '../hooks/useOccReconGridColumnDefs';
import useOccReconRowData from '../hooks/useOccReconRowData';

type Props = {
  overlayNoRowsTemplate?: string;
  sx?: SxProps;
  userDisplayOptions: UserDisplayOptions;
};

function OccReconGrid(props: Props): ReactElement {
  const { sx, overlayNoRowsTemplate, userDisplayOptions } = props;
  const { periodTraceId } = useContractAndPeriodWithVersions();
  const gridRef = useRef<AgGridRef<unknown>>(null);

  const { currentData: reconData } = useGetOccReconGridQuery(
    periodTraceId ?? skipToken,
  );

  const isGridLocked = useLockedGridStatus(LockGrid.OCC_RECONCILIATION_GRID);
  const columnDefs = useGridColDefs(
    useOccReconGridColumnDefs,
    [periodTraceId, isGridLocked],
    isGridLocked,
  );
  const rowData = useOccReconRowData(reconData);
  const gridOptions = useGridOptions(useOccReconGridOptions);

  // biome-ignore lint/correctness/useExhaustiveDependencies: if the data changes or the state of the locked grid changes, we want to redraw the grid
  useEffect(() => {
    gridRef.current?.api?.redrawRows();
  }, [rowData, isGridLocked]);

  return (
    <CondorAgGrid
      ref={gridRef}
      columnDefs={columnDefs}
      gridOptions={gridOptions}
      overlayNoRowsTemplate={overlayNoRowsTemplate}
      rowData={rowData}
      sx={sx}
      userDisplayOptions={userDisplayOptions}
    />
  );
}

export default withPeriodSpecificGridWrapper(
  OccReconGrid,
  PeriodGridBlobType.OCC_RECONCILIATION_GRID,
);
