import { useMemo } from 'react';

import cloneDeep from 'lodash/cloneDeep';

import type { CurrencyViewMode } from 'shared/lib/currency-toggle-group/CurrencyToggleGroup';
import {
  unitDetailFromBackendValue,
  unitTypeFromBackendValue,
} from 'shared/lib/driverUnitTypes';

import type { apiJSON } from 'shared/api/rtkq/apiJSON';

export function processExpenseRow(expenseData: apiJSON | undefined) {
  return cloneDeep(
    expenseData?.rows?.map((row: apiJSON) => {
      const {
        unitType,
        unitDetail,
        monthlyExpenseUnits,
        monthlyExpenseValues,
        monthlyExpenseValuesToggleable,
        ...restOfRow
      } = row;
      const normalizedRow = {
        unitType: unitTypeFromBackendValue(unitType),
        unitDetail: unitDetailFromBackendValue(unitDetail),
        ...restOfRow,
        // BLAs don't have units
        ...monthlyExpenseUnits?.reduce(
          (
            acc: Record<string, number>,
            monthlyExpenseUnit: { month: string; units: number },
          ) => {
            acc[monthlyExpenseUnit.month] = monthlyExpenseUnit.units;
            return acc;
          },
          {},
        ),

        ...monthlyExpenseValuesToggleable.reduce(
          (
            acc: Record<string, Record<CurrencyViewMode, number>>,
            monthlyExpenseValue: {
              month: string;
              value: Record<CurrencyViewMode, number>;
            },
          ) => {
            const { month, value } = monthlyExpenseValue;
            acc[month] = value;
            return acc;
          },
          {},
        ),
      };
      normalizedRow.monthlyExpenseUnits = undefined;
      normalizedRow.monthlyExpenseValues = undefined;

      // To avoid bug in useActivityDriver where we generate AS_INVOICED_LIST for OCC
      for (const month of expenseData.month_list ?? []) {
        normalizedRow[month] ??= undefined;
      }

      return normalizedRow;
    }),
  );
}

export default function useExpenseRowData(expenseData?: apiJSON) {
  return useMemo(() => processExpenseRow(expenseData), [expenseData]);
}
