import { useMemo } from 'react';

import { useLocation, useMatch, useParams } from 'react-router-dom';

import * as routes from 'routes';

function useActiveLink(link?: string) {
  const { pathname } = useLocation();
  const { contractContainerId, forecastId } = useParams();

  const fxRatesTab = useMatch(routes.getFxRatesTab());
  const fxRatesClosedTab = useMatch(
    routes.getFxRatesTab(routes.getCompanyPeriodClosed()),
  );
  const invoicesTab = useMatch(routes.getInvoicesTab());
  const invoicesClosedTab = useMatch(
    routes.getInvoicesTab(routes.getCompanyPeriodClosed()),
  );

  const occExpenses = useMatch(routes.getOccExpenses());
  const siteLab = useMatch(routes.getSiteLabCostMatrixTab());
  const occRecon = useMatch(routes.getOccRecon());
  const clinicalExpensesSubTabByContractContainer = useMatch(
    routes.getClinicalExpensesSubTabByContractContainer(),
  );
  const clinicalExpensesTabByContractContainer = useMatch(
    routes.getClinicalExpensesTabByContractContainer(),
  );
  const forecastTabByForecast = useMatch(routes.getForecastTab());
  const occByContractVersion = useMatch(routes.getOccByContractVersion());
  const financialCloseTab = useMatch(routes.getFinancialCloseTab());
  const analyticsTab = useMatch(routes.getAnalyticsTab());

  const patientJourney = useMatch(routes.getPatientNavigation());
  const patientJourneyTab = useMatch(
    routes.getPatientJourneyByCohortNavigation(),
  );

  return useMemo(() => {
    if (!link) {
      return false;
    }

    // company pages
    if (fxRatesTab) {
      return link === routes.getFxRates(routes.getCompany());
    }

    if (fxRatesClosedTab) {
      return link === routes.getFxRates(routes.getCompanyPeriodClosed());
    }

    if (invoicesTab) {
      return link === routes.getInvoices(routes.getCompany());
    }

    if (invoicesClosedTab) {
      return link === routes.getInvoices(routes.getCompanyPeriodClosed());
    }

    // accruals pages
    if (analyticsTab) {
      return link === routes.getAnalytics();
    }

    if (financialCloseTab) {
      return link === routes.getFinancialClose();
    }

    if (occExpenses || occRecon || occByContractVersion) {
      return link === routes.getOcc();
    }

    if (
      clinicalExpensesSubTabByContractContainer ||
      clinicalExpensesTabByContractContainer
    ) {
      return (
        link ===
        routes.getClinicalExpensesByContractContainer(contractContainerId)
      );
    }

    if (forecastTabByForecast) {
      return link === routes.getSpecificForecast(forecastId);
    }

    if (siteLab) {
      return link === routes.getSiteLabCostMatrix();
    }

    if (patientJourney || patientJourneyTab) {
      return link === routes.getPatientNavigation('patient-activity');
    }

    return pathname === link;
  }, [
    analyticsTab,
    clinicalExpensesSubTabByContractContainer,
    clinicalExpensesTabByContractContainer,
    contractContainerId,
    financialCloseTab,
    forecastId,
    forecastTabByForecast,
    fxRatesTab,
    fxRatesClosedTab,
    invoicesTab,
    invoicesClosedTab,
    link,
    occByContractVersion,
    occExpenses,
    occRecon,
    siteLab,
    patientJourney,
    patientJourneyTab,
    pathname,
  ]);
}

export default useActiveLink;
