import Grid, { gridClasses } from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import ContractFilesSection from 'accruals/pages/clinical-expenses/shared/contract-record/ContractFilesSection';
import ContractRecordSection from 'accruals/pages/clinical-expenses/shared/contract-record/ContractRecordSection';

import ContractAssumptionsSection from '../shared/ContractAssumptionsSection';
import CroContractVersionHistorySection from './CroContractVersionHistorySection';
import CroExpenseSummarySection from './CroExpenseSummarySection';

function CroContractOverviewTabContent() {
  return (
    <Grid mt={0} spacing={3} container>
      <Grid sx={{ [`&.${gridClasses.item}`]: { pt: 0 } }} xs={12} item>
        <CroExpenseSummarySection />
      </Grid>
      <Grid xs={12} item>
        <Grid spacing={3} container>
          <Grid xs={8} item>
            <ContractAssumptionsSection />
          </Grid>
          <Grid xs={4} item>
            <Stack spacing={3}>
              <ContractRecordSection />
              <ContractFilesSection />
            </Stack>
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={12} item>
        <CroContractVersionHistorySection />
      </Grid>
    </Grid>
  );
}

export default CroContractOverviewTabContent;
