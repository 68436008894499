import { type ReactElement, useEffect, useRef } from 'react';

import type { SxProps } from '@mui/material/styles';
import { skipToken } from '@reduxjs/toolkit/query';

import type {
  AgGridRef,
  UserDisplayOptions,
} from 'shared/components/ag-grid/CondorAgGrid';
import CondorAgGrid from 'shared/components/ag-grid/CondorAgGrid';
import useGridColDefs from 'shared/components/ag-grid/hooks/useGridColDefs';
import useGridOptions from 'shared/components/ag-grid/hooks/useGridOptions';
import useLockedGridStatus from 'shared/components/lock-grid/useLockedGridStatus';

import useContractAndPeriodWithVersions from 'accruals/pages/clinical-expenses/shared/hooks/useContractAndPeriodWithVersions';
import useExpenseRowData from 'accruals/pages/clinical-expenses/shared/hooks/useExpenseRowData';
import withPeriodSpecificGridWrapper from 'shared/lib/periods/withPeriodSpecificGridWrapper';
import { LockGrid, PeriodGridBlobType } from 'shared/lib/types';

import { useGetOccExpenseGridQuery } from 'shared/api/rtkq/periods';

import useOccExpenseGridColumnDefs from '../hooks/useOccExpenseGridColumnDefs';
import useOccExpenseGridOptions from '../hooks/useOccExpenseGridOptions';

type Props = {
  overlayNoRowsTemplate?: string;
  sx?: SxProps;
  userDisplayOptions: UserDisplayOptions;
};

function OccExpenseGrid(props: Props): ReactElement {
  const { overlayNoRowsTemplate, sx, userDisplayOptions } = props;
  const gridRef = useRef<AgGridRef<any>>(null); // eslint-disable-line @typescript-eslint/no-explicit-any -- expense grid is not properly typed

  const { periodTraceId } = useContractAndPeriodWithVersions();
  const { currentData: expenseData } = useGetOccExpenseGridQuery(
    periodTraceId ?? skipToken,
  );

  const isGridLocked = useLockedGridStatus(LockGrid.OCC_EXPENSE_GRID);

  const columnDefs = useGridColDefs(
    useOccExpenseGridColumnDefs,
    [expenseData, isGridLocked],
    isGridLocked,
  );
  const gridOptions = useGridOptions(useOccExpenseGridOptions);
  const rowData = useExpenseRowData(expenseData);

  // biome-ignore lint/correctness/useExhaustiveDependencies: if the data changes or the state of the locked grid changes, we want to redraw the grid
  useEffect(() => {
    gridRef.current?.api?.redrawRows();
  }, [rowData, isGridLocked]);

  return (
    <CondorAgGrid
      ref={gridRef}
      columnDefs={columnDefs}
      gridOptions={gridOptions}
      overlayNoRowsTemplate={overlayNoRowsTemplate}
      rowData={rowData}
      sx={sx}
      userDisplayOptions={userDisplayOptions}
    />
  );
}

export default withPeriodSpecificGridWrapper(
  OccExpenseGrid,
  PeriodGridBlobType.OCC_EXPENSE_GRID,
);
