import { createSlice } from '@reduxjs/toolkit';

import type { UserAddForm } from 'shared/lib/types';
import type { RootState } from 'shared/state/store';

const defaultUser: UserAddForm = {
  first_name: '',
  last_name: '',
  email: '',
  third_party_company: { label: '', value: '' },
  title: '',
  trial_access: [],
};

const userManagementSlice = createSlice({
  name: 'userManagement',
  initialState: { user: defaultUser },
  reducers: {
    resetCurrentUserInUserManagement: () => ({ user: defaultUser }),
    setCurrentUserInUserManagement: (state, action) => {
      state.user = { ...state.user, ...action.payload };
    },
  },
});

export const selectCurrentUserForUserManagement = (state: RootState) =>
  state.userManagement;

export const {
  resetCurrentUserInUserManagement,
  setCurrentUserInUserManagement,
} = userManagementSlice.actions;
export const userManagementReducer = userManagementSlice.reducer;
