import { useSelector } from 'react-redux';

import {
  missingPeriodVersion,
  selectPeriodVersion,
} from 'accruals/state/slices/periodVersionSlice'; // eslint-disable-line import/no-restricted-paths -- this files knows how to handle this empty correctly
import type { CommentLocationType } from 'shared/lib/types';
import { selectShowPriorPeriodComments } from 'shared/state/slices/commentSlice';
import { selectCompany } from 'shared/state/slices/companySlice';

import { useGetCommentsWithFiltersQuery } from 'shared/api/rtkq/comments';

type CommentQuery = {
  location_type: CommentLocationType;
  location_slug?: string;
  // NOTE: Its ALWAYS only one of period_version or company or period_lte, never more than one
  period_version?: string;
  company?: string;
  period_lte?: string;
};

export default function useCommentsLoader(
  location: CommentLocationType,
  locationSlug: string,
) {
  const currentPeriodVersion = useSelector(selectPeriodVersion);
  const currentCompany = useSelector(selectCompany);
  const showPriorPeriodComments = useSelector(selectShowPriorPeriodComments);

  const commentQuery: CommentQuery = {
    location_type: location,
    location_slug: locationSlug.trim().length ? locationSlug : undefined,
  };

  if (currentPeriodVersion !== missingPeriodVersion) {
    if (showPriorPeriodComments) {
      commentQuery.period_lte = currentPeriodVersion.period;
    } else {
      commentQuery.period_version = currentPeriodVersion.trace_id;
    }
  } else {
    commentQuery.company = currentCompany.trace_id;
  }

  const { currentData: comments } =
    useGetCommentsWithFiltersQuery(commentQuery);

  return comments;
}
