type ConfigType = {
  VITE_APP_NAME: string;
  VITE_APP_API_HOST: string;
  VITE_APP_APP_API_HOST: string;
  VITE_APP_IS_DEV_ENVIRONMENT: boolean;
  VITE_APP_DISABLE_AUTH: boolean;
  VITE_APP_AUTH0_AUDIENCE: string;
  VITE_APP_AUTH0_DOMAIN: string;
  VITE_APP_AUTH0_CLIENT_ID: string;
  VITE_APP_PAQARINA_SENTRY_DSN: string;
  VITE_APP_ENABLE_MIXPANEL: string;
  VITE_APP_ENABLE_FULLSTORY: string;
  VITE_APP_ZENDESK_SECRET: string;
  IS_CYPRESS_ENVIRONMENT: boolean;
};

const config: ConfigType = {
  VITE_APP_NAME: import.meta.env.VITE_APP_NAME,
  VITE_APP_API_HOST: import.meta.env.VITE_APP_API_HOST,
  VITE_APP_APP_API_HOST: import.meta.env.VITE_APP_APP_API_HOST,
  VITE_APP_IS_DEV_ENVIRONMENT:
    import.meta.env.VITE_APP_IS_DEV_ENVIRONMENT === 'true',
  // This should only ever be True in local environments with no internet connection.
  // Since it would be disastrous to accidentally disable auth normally, we're being
  // overly cautious with three additional checks to confirm we're in a local environment.
  VITE_APP_DISABLE_AUTH:
    import.meta.env
      .VITE_APP_DANGEROUSLY_DISABLE_AUTH_ONLY_BECAUSE_I_HAVE_NO_INTERNET ===
      'true' &&
    import.meta.env.VITE_APP_IS_DEV_ENVIRONMENT === 'true' &&
    import.meta.env.VITE_APP_NAME === 'Local' &&
    import.meta.env.VITE_APP_API_HOST === 'http://localhost:8000',
  VITE_APP_AUTH0_AUDIENCE: import.meta.env.VITE_APP_AUTH0_AUDIENCE,
  VITE_APP_AUTH0_DOMAIN: import.meta.env.VITE_APP_AUTH0_DOMAIN,
  VITE_APP_AUTH0_CLIENT_ID: import.meta.env.VITE_APP_AUTH0_CLIENT_ID,
  VITE_APP_PAQARINA_SENTRY_DSN: import.meta.env.VITE_APP_PAQARINA_SENTRY_DSN,
  VITE_APP_ENABLE_MIXPANEL: import.meta.env.VITE_APP_ENABLE_MIXPANEL,
  VITE_APP_ENABLE_FULLSTORY: import.meta.env.VITE_APP_ENABLE_FULLSTORY,
  VITE_APP_ZENDESK_SECRET: import.meta.env.VITE_APP_ZENDESK_SECRET,
  IS_CYPRESS_ENVIRONMENT: window.Cypress !== undefined,
};

if (
  !config.VITE_APP_AUTH0_DOMAIN ||
  !config.VITE_APP_AUTH0_CLIENT_ID ||
  !config.VITE_APP_AUTH0_AUDIENCE
) {
  throw new Error(
    'Missing `VITE_APP_AUTH0_DOMAIN`, `VITE_APP_AUTH0_CLIENT_ID`, and/or `VITE_APP_AUTH0_AUDIENCE` settings in your `.env` file.',
  );
}
export default config;
