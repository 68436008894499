import { useState } from 'react';

import Typography from '@mui/material/Typography';
import { useDispatch } from 'react-redux';

import KabobMenu from 'shared/components/kabob-menu/KabobMenu';
import Modal from 'shared/components/modal/Modal';

import type { SiteGridRow } from 'shared/lib/types';
import { openEditSite } from 'shared/state/slices/siteSlice';
import useHasPermission from 'shared/lib/permissions/useHasPermission';
import useFeatureFlag from 'shared/helpers/useFeatureFlag';

import { useDeleteSiteContractMutation } from 'shared/api/rtkq/sitecontracts';

type Props = {
  siteGridRow: SiteGridRow;
  onAddNewSiteVersion: (siteTraceId: string) => Promise<void>;
};

function SiteMenu(props: Props) {
  const { siteGridRow, onAddNewSiteVersion } = props;
  const {
    site_trace_id: siteTraceId,
    site_contract_trace_id: siteContractTraceId,
  } = siteGridRow;

  const dispatch = useDispatch();
  const canEditTrialInfo = useHasPermission(['canEditTrialInfo']);
  const isSoftDeleteEnabled = useFeatureFlag('soft_delete');
  const canDeleteTrialInfo = useHasPermission(['canDeleteTrialInfo']);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteSiteContract, { isLoading: isDeletingSiteContract }] =
    useDeleteSiteContractMutation();

  const showDeleteMenu =
    canDeleteTrialInfo && isSoftDeleteEnabled && !!siteContractTraceId;

  const handleSiteContractDelete = async () => {
    await deleteSiteContract(siteContractTraceId!);
    setIsModalOpen(false);
  };

  const onEditSite = () => {
    dispatch(openEditSite({ traceId: siteTraceId! }));
  };

  return (
    <>
      <KabobMenu
        size="small"
        options={[
          ...(canEditTrialInfo && siteTraceId
            ? [
                {
                  label: 'Add new site version',
                  onClick: () => void onAddNewSiteVersion(siteTraceId),
                },
                {
                  label: 'Edit site',
                  onClick: () => onEditSite(),
                },
              ]
            : []),
          ...(showDeleteMenu
            ? [
                {
                  label: 'Delete site',
                  onClick: () => setIsModalOpen(true),
                },
              ]
            : []),
        ]}
      />
      <Modal
        handleClose={() => setIsModalOpen(false)}
        isOpen={isModalOpen}
        title={`Are you sure you want to delete this version of Site #${siteGridRow.number}${siteGridRow.name ? ` (${siteGridRow.name})` : ''}?`}
        ButtonProps={{
          size: 'small',
          label: 'Delete Version',
          testId: 'DeleteSiteContractModal',
          onClick: () => void handleSiteContractDelete(),
          sx: { backgroundColor: 'error.main' },
          loading: isDeletingSiteContract,
        }}
      >
        <Typography>
          If the site version was being used in any trial expenses, accrual and
          forecasting calculations may be affected. This action cannot be
          undone.
        </Typography>
      </Modal>
    </>
  );
}

export default SiteMenu;
