import { type ReactElement, useEffect, useRef } from 'react';

import type { SxProps } from '@mui/material/styles';

import type { AgGridRef } from 'shared/components/ag-grid/CondorAgGrid';
import CondorAgGrid from 'shared/components/ag-grid/CondorAgGrid';
import useGridColDefs from 'shared/components/ag-grid/hooks/useGridColDefs';
import useGridOptions from 'shared/components/ag-grid/hooks/useGridOptions';
import useLockedGridStatus from 'shared/components/lock-grid/useLockedGridStatus';

import withPeriodSpecificGridWrapper from 'shared/lib/periods/withPeriodSpecificGridWrapper';
import type { LabGridRow } from 'shared/lib/types';
import { LockGrid, PeriodGridBlobType } from 'shared/lib/types';

import useLabCostMatrixGridOptions from '../hooks/useLabCostMatrixGridOptions';
import useLabCostMatrixRows from '../hooks/useLabCostMatrixRows';
import useSiteLabCostMatrixColumnDefs from '../hooks/useSiteLabCostMatrixColumnDefs';

type Props = { overlayNoRowsTemplate?: string; sx?: SxProps };

function LabGrid(props: Props): ReactElement {
  const { overlayNoRowsTemplate, sx } = props;
  const gridRef = useRef<AgGridRef<LabGridRow>>(null);

  const isGridLocked = useLockedGridStatus(LockGrid.LAB_COST_MATRIX);
  const columnDefs = useGridColDefs(
    useSiteLabCostMatrixColumnDefs,
    ['LAB', isGridLocked],
    isGridLocked,
  );
  const rows = useLabCostMatrixRows();
  const gridOptions = useGridOptions(useLabCostMatrixGridOptions);

  // biome-ignore lint/correctness/useExhaustiveDependencies: if the data changes or the state of the locked grid changes, we want to redraw the grid
  useEffect(() => {
    gridRef.current?.api?.redrawRows();
  }, [rows, isGridLocked]);

  return (
    <CondorAgGrid
      ref={gridRef}
      columnDefs={columnDefs}
      gridOptions={gridOptions}
      overlayNoRowsTemplate={overlayNoRowsTemplate}
      rowData={rows}
      sx={sx}
    />
  );
}

export default withPeriodSpecificGridWrapper(
  LabGrid,
  PeriodGridBlobType.LAB_COST_MATRIX,
);
